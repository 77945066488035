import { PrinterOutlined } from "@ant-design/icons";
import {
  Select,
  Typography,
  DatePicker,
  Button,
  Space,
  Row,
  Col,
  Divider,
  List,
} from "antd";
import {
  excelIcon as IconExcel,
  pdfIcon as IconPDF,
  arrowIcon as IconArrow,
} from "../../assets/icons";
import HeaderSection from "../../components/HeaderSection";
import useFetchList from "../../hooks/useFetchList";
import React, { useEffect } from "react";
import {
  TransactionProps,
  initialTransactions,
  transactionMoistureContentItemProperties,
} from "../../types/transaction";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { theme } from "../../assets/theme";
import moment from "moment";
import { httpRequest } from "../../helpers/api";
import Section from "../../components/Section";
import { MoistureContentProps } from "../../types/moistureContent.type";
import { BlobProvider, PDFDownloadLink } from "@react-pdf/renderer";
import MyDocument from "./pdf";

export function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

interface ILocation {
  transactionId: string;
}

const { Text } = Typography;

const DetailTransaction = () => {
  const navigate = useNavigate();
  const { transactionId } = useParams<keyof ILocation>() as ILocation;
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [transaction, setTransaction] = React.useState<TransactionProps>({
    ...initialTransactions,
  });
  const transactionCreated = moment(transaction?.createdAt || 0);

  useEffect(() => {
    const fetchTransaction = async () => {
      try {
        setIsLoading(true);
        const res = await httpRequest.get("/transactions/" + transactionId);
        setTransaction(res.data.payload);
        if (res.data.payload.isDraft) {
          navigate("/transactions/" + transactionId + "/edit");
        }
        console.log("Hello World", transaction);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };
    fetchTransaction();

    console.log(transaction);
  }, [transactionId]);

  const printPDFHandler = (value: Blob | null) => {
    if (!value) return;
    const blob = new Blob([value], { type: "application/pdf" });
    const url = window.URL.createObjectURL(blob);

    // Membuat elemen iframe pada dokumen HTML
    const iframe = document.createElement("iframe");
    iframe.style.display = "none";
    iframe.src = url;
    document.body.appendChild(iframe);

    // Mencetak dokumen PDF
    iframe.contentWindow?.print();

    // Menghapus objek URL saat selesai mencetak
    URL.revokeObjectURL(url);
  };

  return (
    <React.Fragment>
      <HeaderSection
        icon={"back"}
        title={transaction.transactionCode || ""}
        rightAction={
          <Space>
            {/* <Button
              icon={<IconExcel style={{ marginRight: 10 }} />}
              style={{
                borderColor: '#55C95A',
                color: '#55C95A',
                backgroundColor: '#FFFFFF',
              }}
            >
              Export Excel
            </Button> */}
            <PDFDownloadLink
              document={<MyDocument transactions={[{ ...transaction }]} />}
              fileName={"TRANSACTION_" + transactionId + ".pdf"}
            >
              <Button
                icon={<IconPDF style={{ marginRight: 10 }} />}
                style={{
                  borderColor: "#FE4545",
                  color: "#FE4545",
                  backgroundColor: "#FFFFFF",
                }}
              >
                Export PDF
              </Button>
            </PDFDownloadLink>
            {/* <BlobProvider
              document={<MyDocument transactions={[{ ...transaction }]} />}
            >
              {({ blob, loading, error }) => {
                if (loading) return <div>Loading...</div>;
                if (error) return <div>Error...</div>;
                return (
                  <Button
                    icon={<PrinterOutlined style={{ marginRight: 10 }} />}
                    style={{
                      borderColor: "#55C95A",
                      color: "#55C95A",
                      backgroundColor: "#FFFFFF",
                    }}
                    onClick={() => printPDFHandler(blob)}
                  >
                    Print
                  </Button>
                );
              }}
            </BlobProvider> */}
          </Space>
        }
      />
      <div style={{ margin: "24px", paddingBottom: "24px" }}>
        <Section
          title="INFORMASI PENGIRIM"
          subtitle="Berikut adalah informasi transaski"
        >
          <Row gutter={[12, 12]}>
            <Col span={12}>
              <Text style={{ color: "#768499", fontSize: "12px" }}>
                Kode Transaksi{" "}
              </Text>
              <br />
              <Text style={{ fontSize: "14px" }}>
                {transaction?.transactionCode}{" "}
              </Text>
              <br />
            </Col>
            <Col span={12}>
              <Text style={{ color: "#768499", fontSize: "12px" }}>
                Dibuat Pada{" "}
              </Text>
              <br />
              <Text style={{ fontSize: "14px" }}>
                {transactionCreated.format("DD MMM YYYY, HH:mm")}
              </Text>
              <br />
            </Col>
          </Row>
        </Section>
        <Divider />
        <Section
          title="IDENTITAS PENGIRIM"
          subtitle="Berikut adalah identitas nama , jenis mobil, dan plat nomornya"
        >
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Text style={{ color: "#768499", fontSize: "12px" }}>Nama </Text>
              <br />
              <Text style={{ fontSize: "14px" }}>
                {transaction?.senderName}{" "}
              </Text>
            </Col>
            <Col span={12}>
              <Text style={{ color: "#768499", fontSize: "12px" }}>
                Jenis Mobil{" "}
              </Text>
              <br />
              <Text style={{ fontSize: "14px" }}>
                {transaction?.senderCarType}{" "}
              </Text>
            </Col>
            <Col span={12}>
              <Text style={{ color: "#768499", fontSize: "12px" }}>
                Plat Nomor{" "}
              </Text>
              <br />
              <Text style={{ fontSize: "14px" }}>
                {transaction?.senderPlateNumber}
              </Text>
            </Col>
          </Row>
        </Section>
        <Divider />
        <Section
          title="BERAT"
          subtitle="Berikut adalah perhitungan berat jagung kotor.
          Berat Jagung Kotor = Berat Kotor - Berat Kosong"
        >
          <Row gutter={[12, 12]}>
            <Col style={{ padding: 0 }} span={24}>
              <Row style={{ padding: "0px 8px" }}>
                <Col style={{ margin: "auto 0" }} span={20}>
                  <Text type="secondary">
                    Berat Kotor (Berat Barang + Pickup/Truck)
                  </Text>
                </Col>
                <Col style={{ margin: "auto 0", textAlign: "right" }} span={4}>
                  <Text strong style={{ fontSize: "14px" }}>
                    {(transaction?.grossWeight)
                      .toLocaleString("id-ID")
                      .replace(/,/g, ".")}{" "}
                    Kg
                  </Text>
                </Col>
              </Row>
            </Col>
            <Col style={{ padding: 0 }} span={24}>
              <Row style={{ padding: "0px 8px" }}>
                <Col style={{ margin: "auto 0" }} span={20}>
                  <Text type="secondary">
                    Berat Kosong (Berat Pickup/Truck){" "}
                  </Text>
                </Col>
                <Col style={{ margin: "auto 0", textAlign: "right" }} span={4}>
                  <Text strong style={{ fontSize: "14px", color: "#FE4545" }}>
                    (
                    {transaction?.emptyWeight
                      .toLocaleString("id-ID")
                      .replace(/,/g, ".")}
                    ) Kg
                  </Text>
                </Col>
              </Row>
            </Col>
            <Col style={{ padding: 0 }} span={24}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "9px 8px",
                  backgroundColor: "#E8ECF3",
                }}
              >
                <Text strong>Berat Jagung Kotor </Text>
                <Text strong style={{ fontSize: "14px" }}>
                  {(transaction.grossWeight - transaction.emptyWeight)
                    .toLocaleString("id-ID")
                    .replace(/,/g, ".")}{" "}
                  Kg
                </Text>
              </div>
            </Col>
          </Row>
        </Section>
        <Divider />
        <Section
          title="KADAR AIR"
          subtitle="Berikut adalah kadar air.
          Kadar Air diisi dengan jumlah kadar air pada jagung dari total berat jagung kotor"
        >
          <List
            itemLayout="horizontal"
            dataSource={transaction.TransactionMoistureContents}
            renderItem={(item: transactionMoistureContentItemProperties) => {
              // parse json to object
              const metaMoistureContent: MoistureContentProps =
                item.metaMoistureContent;
              return (
                <Row>
                  <Col style={{ margin: "auto 0" }} span={3}>
                    <Text style={{ color: "#768499" }}>
                      {metaMoistureContent.minimumLimit} -{" "}
                      {metaMoistureContent.maximumLimit}
                    </Text>
                  </Col>
                  <Col style={{ margin: "auto 0" }} span={2}>
                    <Text strong style={{ fontSize: "14px" }}>
                      {item.seedWeight} Kg
                    </Text>
                  </Col>
                  <Col style={{ margin: "auto 0", padding: "15px" }} span={1}>
                    <Text style={{ color: "#768499" }}>x</Text>
                  </Col>
                  <Col style={{ margin: "auto 0" }} span={11}>
                    <Text style={{ color: "#768499" }}>
                      Potongan {metaMoistureContent.percentage} %
                    </Text>
                  </Col>
                  <Col
                    style={{ margin: "auto 0", textAlign: "right" }}
                    span={7}
                  >
                    <Text strong style={{ fontSize: "14px" }}>
                      {((100 - metaMoistureContent.percentage) *
                        item.seedWeight) /
                        100}{" "}
                      Kg
                    </Text>
                  </Col>
                </Row>
              );
            }}
            footer={
              <Col style={{ padding: 0 }} span={24}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "9px 8px",
                    backgroundColor: "#E8ECF3",
                  }}
                >
                  <Text strong>Berat Jagung Bersih </Text>
                  <Text strong style={{ fontSize: "14px" }}>
                    {transaction.netWeight
                      .toLocaleString("id-ID")
                      .replace(/,/g, ".")}{" "}
                    Kg
                  </Text>
                </div>
              </Col>
            }
          />
        </Section>
        <Divider />
        <Section
          title="RINGKASAN TRANSAKSI"
          subtitle="Berikut adalah total pengeluaran dari pengurangan Harga Jagung Bersih dengan Total Potongan."
          style={{ backgroundColor: "#F4F6F9" }}
        >
          <Row gutter={[12, 12]}>
            <Col span={24} style={{ padding: "0px 8px" }}>
              <Row>
                <Col style={{ margin: "auto 0" }} span={5}>
                  <Text>Harga Jagung Bersih</Text>
                </Col>
                <Col style={{ margin: "auto 0", padding: "15px" }} span={3}>
                  <Text style={{ color: "#768499" }}>
                    {transaction.netWeight
                      .toLocaleString("id-ID")
                      .replace(/,/g, ".")}{" "}
                    kg
                  </Text>
                </Col>
                <Col style={{ margin: "auto 0" }} span={1}>
                  <Text style={{ color: "#768499" }}>x</Text>
                </Col>
                <Col style={{ margin: "auto 0" }} span={4}>
                  <Text strong style={{ fontSize: "14px" }}>
                    {transaction.netCornPrice
                      .toLocaleString("id-ID")
                      .replace(/,/g, ".")}
                  </Text>
                </Col>
                <Col span={7}></Col>
                <Col
                  style={{
                    margin: "auto 0",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  span={4}
                >
                  <Text strong style={{ fontSize: "14px" }}>
                    Rp
                  </Text>
                  <Text strong style={{ fontSize: "14px" }}>
                    {(transaction.netCornPrice * transaction.netWeight)
                      .toLocaleString("id-ID")
                      .replace(/,/g, ".")}
                  </Text>
                </Col>
              </Row>
            </Col>
            {/* {transaction.deadSeedPrice > 0 && (
              <Col span={24} style={{ padding: "0px 8px" }}>
                <Row gutter={[0, 10]}>
                  <Col style={{ margin: "auto 0" }} span={5}>
                    <Text>Biji Mati</Text>
                  </Col>
                  <Col style={{ margin: "auto 0", padding: "15px" }} span={3}>
                    <Text style={{ color: "#768499" }}>
                      {transaction.netWeight} kg
                    </Text>
                  </Col>
                  <Col style={{ margin: "auto 0" }} span={1}>
                    <Text style={{ color: "#768499" }}>x</Text>
                  </Col>
                  <Col style={{ margin: "auto 0" }} span={4}>
                    <Text strong style={{ fontSize: "14px" }}>
                      {transaction.deadSeedPrice || 0}
                    </Text>
                  </Col>
                  <Col span={7}></Col>
                  <Col
                    style={{
                      margin: "auto 0",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    span={4}
                  >
                    <Text strong style={{ fontSize: "14px", color: "#FE4545" }}>
                      Rp
                    </Text>
                    <Text strong style={{ fontSize: "14px", color: "#FE4545" }}>
                      (
                      {(transaction.deadSeedPrice * transaction.netWeight)
                        .toLocaleString("id-ID")
                        .replace(/,/g, ".")}
                      )
                    </Text>
                  </Col>
                  {!transaction.deadSeedComment && (
                    <Col style={{ margin: "auto 0" }} span={24}>
                      <Space>
                        <IconArrow />
                        <Text>{transaction.deadSeedComment}</Text>
                      </Space>
                    </Col>
                  )}
                </Row>
              </Col>
            )}
            {transaction.limeSeedPrice > 0 && (
              <Col span={24} style={{ padding: "0px 8px" }}>
                <Row gutter={[0, 10]}>
                  <Col style={{ margin: "auto 0" }} span={5}>
                    <Text>Biji Kapur</Text>
                  </Col>
                  <Col style={{ margin: "auto 0", padding: "15px" }} span={3}>
                    <Text style={{ color: "#768499" }}>
                      {transaction.netWeight} kg
                    </Text>
                  </Col>
                  <Col style={{ margin: "auto 0" }} span={1}>
                    <Text style={{ color: "#768499" }}>x</Text>
                  </Col>
                  <Col style={{ margin: "auto 0" }} span={4}>
                    <Text strong style={{ fontSize: "14px" }}>
                      {transaction.limeSeedPrice || 0}
                    </Text>
                  </Col>
                  <Col span={7}></Col>
                  <Col
                    style={{
                      margin: "auto 0",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    span={4}
                  >
                    <Text strong style={{ fontSize: "14px", color: "#FE4545" }}>
                      Rp
                    </Text>
                    <Text strong style={{ fontSize: "14px", color: "#FE4545" }}>
                      ({transaction.limeSeedPrice * transaction.netWeight})
                    </Text>
                  </Col>
                  {!transaction.limeSeedComment && (
                    <Col style={{ margin: "auto 0" }} span={24}>
                      <Space>
                        <IconArrow />
                        <Text>{transaction.limeSeedComment}</Text>
                      </Space>
                    </Col>
                  )}
                </Row>
              </Col>
            )} */}
            {transaction.briLink > 0 && (
              <Col span={24} style={{ padding: "0px 8px" }}>
                <Row gutter={[0, 10]}>
                  <Col style={{ margin: "auto 0" }} span={20}>
                    <Text>Bri Link</Text>
                  </Col>
                  {/* <Col style={{ margin: "auto 0", padding: "15px" }} span={3}>
                    <Text style={{ color: "#768499" }}>
                      {transaction.netWeight} kg
                    </Text>
                  </Col> */}
                  {/* <Col style={{ margin: "auto 0" }} span={1}>
                    <Text style={{ color: "#768499" }}>x</Text>
                  </Col>
                  <Col style={{ margin: "auto 0" }} span={4}>
                    <Text strong style={{ fontSize: "14px" }}>
                      {transaction.limeSeedPrice || 0}
                    </Text>
                  </Col> */}
                  {/* <Col span={7}></Col> */}
                  <Col
                    style={{
                      margin: "auto 0",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    span={4}
                  >
                    <Text strong style={{ fontSize: "14px", color: "#FE4545" }}>
                      Rp
                    </Text>
                    <Text strong style={{ fontSize: "14px", color: "#FE4545" }}>
                      (
                      {transaction.briLink
                        .toLocaleString("id-ID")
                        .replace(/,/g, ".")}
                      )
                    </Text>
                  </Col>
                </Row>
              </Col>
            )}
            <Col style={{ padding: 0 }} span={24}>
              <Row style={{ padding: "9px 8px", backgroundColor: "#1E1E1E" }}>
                <Col style={{ margin: "auto 0" }} span={20}>
                  <Text strong style={{ color: "#FFFFFF" }}>
                    Total Transaksi
                  </Text>
                </Col>
                <Col
                  style={{
                    margin: "auto 0",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  span={4}
                >
                  <Text strong style={{ fontSize: "14px", color: "#FFFFFF" }}>
                    Rp
                  </Text>
                  <Text strong style={{ fontSize: "14px", color: "#FFFFFF" }}>
                    {transaction.totalPrice
                      .toLocaleString("id-ID")
                      .replace(/,/g, ".")}
                  </Text>
                </Col>
              </Row>
            </Col>
          </Row>
        </Section>
      </div>
    </React.Fragment>
  );
};

export default DetailTransaction;
