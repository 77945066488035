import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Table,
  Input,
  Typography,
  DatePicker,
  Button,
  Space,
  Row,
  Col,
  Card,
  message,
  Popconfirm,
} from "antd";
import {
  carbonGeneratePdf as IconExportPDF,
  printIcon as IconPrint,
} from "../../assets/icons";
import { excelIcon as IconExcel, pdfIcon as IconPDF } from "../../assets/icons";
import { ReactComponent as IconChevron } from "../../assets/icons/icon-chevron.svg";
import { ReactComponent as IconShop } from "../../assets/icons/icon-shop.svg";
import { ReactComponent as IconMenu } from "../../assets/icons/icon-menu.svg";
import { ReactComponent as IconWeight } from "../../assets/icons/icon-weight.svg";
import locale from "antd/es/date-picker/locale/id_ID";
import HeaderSection from "../../components/HeaderSection";
import { formatYearToTime } from "../../helpers/constant";
import React, { useMemo, useState } from "react";
import { TransactionProps } from "../../types/transaction";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { theme } from "../../assets/theme";
import { ContainerFilter } from "../user";
import { formatPrice } from "../../helpers/priceFormat";
import moment from "moment";
import TransactionItemSummary from "../../components/Dashboard/TransactionItemSummary";
import { httpRequest } from "../../helpers/api";
import { BlobProvider, PDFDownloadLink } from "@react-pdf/renderer";
import MyDocument from "./pdf";
import {AppTable, IAppTableColumn, PAGE_SIZE_OPTIONS, useFetchList} from '@qlibs/react-components';

export function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const { Text } = Typography;

const { RangePicker } = DatePicker;

const Transaction = () => {
  const navigate = useNavigate();
  const {
    isLoading,
    data,
    pagination,
    raw,
    // setData,
    handleSearch,
    setQuery,
    changePage,
    changeLimit,
    fetchList,
    // setIsLoading,
  } = useFetchList<TransactionProps>({
    httpRequest: httpRequest as any,
    endpoint: 'transactions',
    limit: 10,
    initialQuery: {
      isDraft: '0',
    },
    pageQuery: {
      defaultValue: {
        page: 1,
        limit: 10,
        isDraft: '0',
      },
    },
  });

  const handleClickCreate = () => {
    navigate(`/transactions/create`);
  };

  const printPDFHandler = (value: Blob | null) => {
    if (!value) return;
    const blob = new Blob([value], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);

    // Membuat elemen iframe pada dokumen HTML
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = url;
    document.body.appendChild(iframe);

    // Mencetak dokumen PDF
    iframe.contentWindow?.print();

    // Menghapus objek URL saat selesai mencetak
    URL.revokeObjectURL(url);
  };

  const handleDelete = async (id: string) => {
    try {
      await httpRequest.delete(`transactions/${id}`);
      message.success('Berhasil menghapus data');
      fetchList();
    } catch (err) {
      message.error('Gagal menghapus data');
    }
  };

  const columns: IAppTableColumn<TransactionProps>[] = [
    {
      title: 'TANGGAL DIBUAT',
      key: 'createdAt',
      dataIndex: 'createdAt',
      render: (createdAt: number, record: TransactionProps) => {
        return (
          <Text className="text-3 text-primary-black block">
            {formatYearToTime(record.createdAt)}
          </Text>
        );
      },
    },
    {
      title: 'KODE TRANSAKSI',
      key: 'transactionCode',
      dataIndex: 'transactionCode',
      render: (text: string, record: TransactionProps) => {
        return (
          <div
            className="table-link text-3 text-primary-black"
            onClick={() => handleClickDetail(record)}
          >
            {text}
          </div>
        );
      },
    },
    {
      title: 'PENGIRIM',
      key: '',
      dataIndex: ['metaBuyer', 'name'],
      render: (text: string, record: TransactionProps) => {
        return (
          <div>
            <Text className="text-3 text-primary-black block">
              {record.senderName}
            </Text>
            <div className="flex items-center gap-x-2">
              <Text className="text-3 capitalize text-gray-dark">
                {record.senderCarType}
              </Text>
              <Text className="text-3 capitalize text-gray-dark">
                {record.senderPlateNumber.toUpperCase()}
              </Text>
            </div>
          </div>
        );
      },
    },
    {
      title: 'BERAT KOTOR (KG)',
      key: 'grossWeight',
      align: 'right' as 'right',
      dataIndex: 'grossWeight',
      render: (text: string, record: TransactionProps, index: number) => {
        return (
          <Text className="text-3 text-primary-black block text-right">
            {record.grossWeight}
          </Text>
        );
      },
    },
    {
      title: 'BERAT KOSONG (KG)',
      key: 'emptyWeight',
      align: 'right' as 'right',
      dataIndex: 'emptyWeight',
      render: (text: string, record: TransactionProps, index: number) => {
        return (
          <Text className="text-3 text-primary-black block text-right">
            {record.emptyWeight}
          </Text>
        );
      },
    },
    {
      title: 'BERAT BERSIH (KG)',
      key: 'netWeight',
      align: 'right' as 'right',
      dataIndex: 'netWeight',
      render: (text: string, record: TransactionProps, index: number) => {
        return (
          <Text className="text-3 text-primary-black block text-right">
            {record.netWeight}
          </Text>
        );
      },
    },
    {
      title: 'TOTAL (RP)',
      key: 'totalPrice',
      align: 'right' as 'right',
      dataIndex: 'totalPrice',
      render: (text: string, record: TransactionProps, index: number) => {
        return (
          <Text className="text-3 text-primary-black block text-right">
            {formatPrice(record.totalPrice)}
          </Text>
        );
      },
    },
    {
      title: 'ACTION',
      key: 'action',
      align: 'center' as 'center',
      dataIndex: 'action',
      render: (text: string, record: TransactionProps, index: number) => {
        return (
          <Row>
            {/* <Col span={8}>
              <BlobProvider
                document={<MyDocument transactions={[{ ...record }]} />}
              >
                {({ blob, url, loading, error }) => {
                  return (
                    <Button
                      // style={{
                      //   border: "none",
                      // }}
                      type="text"
                      onClick={() => printPDFHandler(blob)}
                    >
                      <IconPrint fontSize={12} />
                    </Button>
                  );
                }}
              </BlobProvider>
            </Col> */}
            <Col span={12}>
              <PDFDownloadLink
                document={<MyDocument transactions={[{ ...record }]} />}
                fileName={`${record.transactionId}.pdf`}
              >
                <Button type="text">
                  <IconExportPDF />
                </Button>
              </PDFDownloadLink>
            </Col>
            <Col span={12}>
              <Popconfirm
                title="Anda yakin Menghapus Transaksi?"
                icon={<ExclamationCircleOutlined style={{ color: 'red' }} />}
                placement="left"
                onConfirm={() => handleDelete(record.transactionId)}
                // onCancel={cancel}
                okText="Yes"
                cancelText="No"
              >
                <Button type="text">
                  <DeleteOutlined
                    style={{ color: 'red', fontSize: 18, padding: '3px 0' }}
                  />
                </Button>
              </Popconfirm>
            </Col>
          </Row>
        );
      },
    },
  ];

  const handleClickDetail = (e: TransactionProps) => {
    navigate(`/transactions/${e.transactionId}`);
  };
  const [dateFilter, setDateFilter] = useState<{
    startAt: string;
    endAt: string;
  }>({
    startAt: '',
    endAt: '',
  });

  const getDateFormat = (date: any) => {
    var d = moment(date);
    return date && d.isValid() ? d : '';
  };

  const [dateFilterTimeSpan, setDateFilterTimeSpan] = useState<any>({
    startAt: undefined,
    endAt: undefined,
  });

  const [filterType, setFilterType] = useState('time_span');
  const [resFilterType, setResFilterType] = useState('');
  const [resFilterDate, setResFilterDate] = useState<any>(undefined);

  // const totalExpenses = useMemo(
  //   () => data.reduce((acc, curr) => acc + curr.totalPrice, 0),
  //   [data]
  // );
  // const totalCornWeight = useMemo(
  //   () => data.reduce((acc, curr) => acc + curr.netWeight, 0),
  //   [data]
  // );
  // const totalTransactions = useMemo(() => data.length, [data]);

  const totalExpenses = useMemo(() => raw?.summary?.totalTotalPrice, [raw]);
  const totalCornWeight = useMemo(() => raw?.summary?.totalNetWeight, [raw]);
  const totalTransactions = useMemo(() => raw?.count || 0, [raw]);

  const handleDateFilter = () => {
    if (dateFilter.startAt && dateFilter.endAt) {
      setQuery((oldVal) => ({
        ...oldVal,
        startAt: moment.utc(dateFilter.startAt).toISOString(),
        endAt: moment.utc(dateFilter.endAt).toISOString(),
      }));
      setResFilterType(filterType);
      setResFilterDate(dateFilter);
    } else {
      setQuery((oldVal) => ({
        ...oldVal,
        startAt: '',
        endAt: '',
      }));
      setResFilterType(filterType);
      setResFilterDate(dateFilter);
    }
  };

  const handleExportExcel = async () => {
    const response = await httpRequest.get<any>(
      `transactions/export/excel${
        dateFilterTimeSpan.startAt && dateFilterTimeSpan.endAt
          ? `?filterStartAt=${dateFilterTimeSpan.startAt}&filterEndAt=${dateFilterTimeSpan.endAt}`
          : ''
      }`,
      {
        responseType: 'blob',
      }
    );

    const contentDisposition = response.headers['content-disposition'];
    const filename = contentDisposition.split('attachment; filename=')[1];

    const blob = new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    console.log(response);
  };

  return (
    <React.Fragment>
      <HeaderSection
        // icon={<AntDesignOutlined />}
        title="Transaksi"
        rightAction={
          <Button
            type="primary"
            onClick={handleClickCreate}
            style={{ width: '175px', marginRight: '20px', fontSize: '12px' }}
            size="small"
          >
            Tambah Transaksi
          </Button>
        }
      />
      <Card
        className="transaction-card"
        style={{
          border: '1px solid #E8ECF3',
          boxShadow:
            '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)',
          margin: '20px',
        }}
      >
        <Row>
          <Col
            span={24}
            style={{
              padding: '10px 20px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <WrapperFilter>
              <div className="filter-item">
                <Text className="title">Tanggal Awal</Text>
                <DatePicker
                  locale={locale}
                  bordered={false}
                  suffixIcon={<IconChevron />}
                  style={{ width: 150 }}
                  format={'DD MMM YYYY'}
                  onChange={(date, dateString) => {
                    const startDate = moment(date).format();
                    setDateFilter({
                      ...dateFilter,
                      startAt: startDate,
                    });
                    setDateFilterTimeSpan({
                      ...dateFilterTimeSpan,
                      startAt: startDate,
                    });
                  }}
                  value={getDateFormat(dateFilterTimeSpan.startAt) as any}
                />
              </div>
              <div className="filter-item">
                <Text className="title">Tanggal Akhir</Text>
                <DatePicker
                  locale={locale}
                  bordered={false}
                  suffixIcon={<IconChevron />}
                  format={'DD MMM YYYY'}
                  style={{ width: 150 }}
                  onChange={(date, dateString) => {
                    const endDate = moment(date).format();
                    setDateFilter({
                      ...dateFilter,
                      endAt: endDate,
                    });
                    setDateFilterTimeSpan({
                      ...dateFilterTimeSpan,
                      endAt: endDate,
                    });
                  }}
                  value={getDateFormat(dateFilterTimeSpan.endAt) as any}
                />
              </div>
            </WrapperFilter>
            <Space>
              <Button
                type="ghost"
                onClick={handleExportExcel}
                loading={isLoading}
                icon={
                  <IconExcel
                    style={{ marginRight: 10, verticalAlign: 'middle' }}
                  />
                }
                size="small"
                style={{
                  width: '130px',
                  border: '2px solid #55C95A',
                  color: '#55C95A',
                  fontSize: '12px',
                }}
              >
                Export Excel
              </Button>
              <PDFDownloadLink
                document={<MyDocument transactions={data} />}
                fileName={
                  dateFilterTimeSpan.startAt && dateFilterTimeSpan.endAt
                    ? `${dateFilterTimeSpan.startAt}-${dateFilterTimeSpan.endAt}.pdf`
                    : `all-export.pdf`
                }
              >
                <Button
                  type="ghost"
                  loading={isLoading}
                  icon={
                    <IconPDF
                      style={{ marginRight: 10, verticalAlign: 'middle' }}
                    />
                  }
                  size="small"
                  style={{
                    width: '130px',
                    border: '2px solid #FE4545',
                    color: '#FE4545',
                    fontSize: '12px',
                  }}
                >
                  Export PDF
                </Button>
              </PDFDownloadLink>
              <Button
                type="primary"
                onClick={handleDateFilter}
                loading={isLoading}
                style={{ width: '150px', fontSize: '12px' }}
                size="small"
              >
                Terapkan Filter
              </Button>
            </Space>
          </Col>
          <Col span={8}>
            <TransactionItemSummary
              title="Total Pengeluaran"
              icon={<IconShop />}
              sup="Rp"
              total={totalExpenses}
            />
          </Col>
          <Col span={8}>
            <TransactionItemSummary
              title="Total Berat Jagung"
              icon={<IconWeight />}
              sup="Kg"
              total={totalCornWeight}
            />
          </Col>
          <Col span={8}>
            <TransactionItemSummary
              title="Total Transaksi"
              icon={<IconMenu />}
              total={totalTransactions}
            />
          </Col>
        </Row>
      </Card>

      <div style={{ padding: '0px 20px' }}>
        {/* <Button onClick={() => console.log(data)}>Show data</Button> */}
        <ContainerFilter>
          <div style={{ width: '100%' }}>
            <h4>Search</h4>
            <Input
              // size="small"
              placeholder="Cari ID atau Nama"
              style={{ width: '100%' }}
              prefix={<SearchOutlined />}
              allowClear
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div>

          {/* <h4 style={labelStyle}>Status</h4> */}
          {/* <Select
            // size="small"
            allowClear
            style={{ width: 200 }}
            onChange={handleTransactionStatusFilterChange}
            placeholder="Transaction Status"
          >
            <Option value="all">All</Option>
            <Option value={ETransactionStatus.created}>Waiting Payment</Option>
            <Option value={ETransactionStatus.canceled}>Canceled</Option>
            <Option value={ETransactionStatus.expired}>Expired</Option>
            <Option value={ETransactionStatus.paid}>Paid</Option>
            <Option value={ETransactionStatus.payment_failed}>
              Payment Failed
            </Option>
            <Option value={ETransactionStatus.process}>Process</Option>
            <Option value={ETransactionStatus.received}>Received</Option>
            <Option value={ETransactionStatus.refunded}>Refunded</Option>
            <Option value={ETransactionStatus.rejected}>Rejected</Option>
            <Option value={ETransactionStatus.sent}>Sent</Option>
          </Select>
          <CustomRangePicker onChange={handleDateRangeFilterChange} /> */}
        </ContainerFilter>

        <AppTable
          isLoading={isLoading}
          keyId="transactionId"
          columns={columns}
          data={data}
          pagination={pagination}
          onChangeLimit={changeLimit}
          onChangePage={changePage}
          _table={
            {
              rowClassName: (_record: any, index: number) =>
                index % 2 === 0 ? 'table-row-light' : 'table-row-dark',
            } as any
          }
        />
      </div>
    </React.Fragment>
  );
};

export const CustomRangePicker = styled(RangePicker)`
  border: 0px;
  border: 1px solid ${theme.colors.ash500};
  width: 344px;
`;

const WrapperFilter = styled.div`
  display: flex;

  .filter-item {
    width: 250px;

    display: flex;
    justify-content: start;
    align-items: center;

    .title {
      font-size: 10px !important;
      color: ${theme.colors.grayDark};
    }
  }
`;

export default Transaction;
