import {
  Table,
  Select,
  Typography,
  DatePicker,
  Button,
  Space,
  Row,
  Col,
  Card,
  Divider,
  Tabs,
  Spin,
  Popconfirm,
  message,
} from "antd";
import {
  carbonGeneratePdf as IconExportPDF,
  printIcon as IconPrint,
} from "../../assets/icons";

import locale from "antd/es/date-picker/locale/id_ID";
import { formatYearToTime } from "../../helpers/constant";
import React, { useEffect, useMemo, useState } from "react";
import { ReactComponent as IconChevron } from "../../assets/icons/icon-chevron.svg";
import { ReactComponent as IconShop } from "../../assets/icons/icon-shop.svg";
import { ReactComponent as IconMenu } from "../../assets/icons/icon-menu.svg";
import { ReactComponent as IconWeight } from "../../assets/icons/icon-weight.svg";
import { ReactComponent as IconExtra } from "../../assets/icons/icon-extra-tabs.svg";

import { TransactionProps } from "../../types/transaction";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { theme } from "../../assets/theme";
import { Link } from "react-router-dom";
import ChartDashboard from "../../components/Dashboard/ChartDashboardPengeluaran";
import TransactionItemSummary from "../../components/Dashboard/TransactionItemSummary";
import moment from "moment";
import { formatPrice } from "../../helpers/priceFormat";
import ChartDashboardCornWeight from "../../components/Dashboard/ChartDashboardCornWeight";
import ChartDashboardTransactions from "../../components/Dashboard/ChartDashboardTransactions";
import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { BlobProvider, PDFDownloadLink } from "@react-pdf/renderer";
import MyDocument from "../transaction/pdf";
import { httpRequest } from "../../helpers/api";
import { useFetchList } from '@qlibs/react-components';
const { Text } = Typography;

interface DataType {
  key: string;
  kadarAir: string;
  potongan: string;
}

const Dashboard = () => {
  const navigate = useNavigate();
  const { isLoading, raw, data, setQuery, fetchList } =
    useFetchList<TransactionProps>({
      httpRequest: httpRequest as any,
      endpoint: "transactions",
      limit: 100000000,
      initialQuery: {
        startAt: moment.utc(moment().startOf("year").format()).toISOString(),
        endAt: moment.utc(moment().endOf("year").format()).toISOString(),
      },
    });

  const handleClickDetail = (e: TransactionProps) => {
    navigate(`/transactions/${e.transactionId}`);
  };
  const [dateFilter, setDateFilter] = useState<{
    startAt: string;
    endAt: string;
  }>({
    startAt: "",
    endAt: "",
  });

  const getDateFormat = (date: any) => {
    var d = moment(date);
    return date && d.isValid() ? d : "";
  };

  const [dateFilterTimeSpan, setDateFilterTimeSpan] = useState<any>({
    startAt: undefined,
    endAt: undefined,
  });
  const [dateFilterMonth, setDateFilterMonth] = useState<any>(undefined);
  const [dateFilterYear, setDateFilterYear] = useState<any>(undefined);
  const [filterType, setFilterType] = useState("year");
  const [resFilterType, setResFilterType] = useState("year");
  const [resFilterDate, setResFilterDate] = useState<any>(undefined);
  const [typeTab, setTypeTab] = useState("");

  useEffect(() => {
    setDateFilterYear(moment().format("YYYY"));
    setResFilterDate({
      startAt: moment().startOf("year").format(),
      endAt: moment().endOf("year").format(),
    });
    setDateFilter({
      startAt: moment.utc(moment().startOf("year").format()).toISOString(),
      endAt: moment.utc(moment().endOf("year").format()).toISOString(),
    });
  }, []);

  function resetDatePicker() {
    setDateFilterTimeSpan({
      startAt: undefined,
      endAt: undefined,
    });
    setDateFilterYear(moment().format("YYYY"));
    setDateFilter({
      startAt: moment.utc(moment().startOf("year").format()).toISOString(),
      endAt: moment.utc(moment().endOf("year").format()).toISOString(),
    });

    setQuery((oldVal) => ({
      ...oldVal,
      startAt: moment.utc(moment().startOf("year").format()).toISOString(),
      endAt: moment.utc(moment().endOf("year").format()).toISOString(),
    }));
    setDateFilterMonth(undefined);
    // setDateFilterYear(undefined);
  }

  const handleChageFilterType = (e: any) => {
    resetDatePicker();

    setFilterType(e);

    // setQuery((oldVal) => ({
    //   ...oldVal,
    //   startAt: undefined,
    //   endAt: undefined,
    // }));
    // setResFilterType('');
  };

  const resetFilter = () => {
    setQuery((oldVal) => ({
      ...oldVal,
      startAt: undefined,
      endAt: undefined,
    }));
    setFilterType("year");
    resetDatePicker();
    setResFilterType("year");
    setTypeTab("pengeluaran");
  };

  // const totalExpenses = useMemo(
  //   () => data.reduce((acc, curr) => acc + curr.totalPrice, 0),
  //   [data]
  // );
  // const totalCornWeight = useMemo(
  //   () => data.reduce((acc, curr) => acc + curr.netWeight, 0),
  //   [data]
  // );
  // const totalTransactions = useMemo(() => data.length, [data]);

  const totalExpenses = useMemo(() => raw?.summary?.totalTotalPrice, [raw]);
  const totalCornWeight = useMemo(() => raw?.summary?.totalNetWeight, [raw]);
  const totalTransactions = useMemo(() => raw?.count || 0, [raw]);

  const handleDateFilter = () => {
    if (dateFilter.startAt && dateFilter.endAt) {
      setQuery((oldVal) => ({
        ...oldVal,
        startAt: moment.utc(dateFilter.startAt).toISOString(),
        endAt: moment.utc(dateFilter.endAt).toISOString(),
      }));

      setResFilterType(filterType);
      setResFilterDate(dateFilter);
    } else {
      setQuery((oldVal) => ({
        ...oldVal,
        startAt: "",
        endAt: "",
      }));
      setResFilterType(filterType);
      setResFilterDate(dateFilter);
    }
  };

  const printPDFHandler = (value: Blob | null) => {
    if (!value) return;
    const blob = new Blob([value], { type: "application/pdf" });
    const url = window.URL.createObjectURL(blob);

    // Membuat elemen iframe pada dokumen HTML
    const iframe = document.createElement("iframe");
    iframe.style.display = "none";
    iframe.src = url;
    document.body.appendChild(iframe);

    // Mencetak dokumen PDF
    iframe.contentWindow?.print();

    // Menghapus objek URL saat selesai mencetak
    URL.revokeObjectURL(url);
  };

  const handleDelete = async (id: string) => {
    try {
      await httpRequest.delete(`transactions/${id}`);
      message.success("Berhasil menghapus data");
      fetchList();
    } catch (err) {
      message.error("Gagal menghapus data");
    }
  };

  const columns = [
    {
      title: "TANGGAL DIBUAT",
      key: "createdAt",
      dataIndex: "createdAt",
      render: (createdAt: number, record: TransactionProps) => {
        return (
          <Text className="text-3.5 text-primary-black block">
            {formatYearToTime(record.createdAt)}
          </Text>
        );
      },
    },
    {
      title: "KODE TRANSAKSI",
      key: "transactionCode",
      dataIndex: "transactionCode",
      render: (text: string, record: TransactionProps) => {
        return (
          <div
            className="table-link text-3.5 text-primary-black"
            onClick={() => handleClickDetail(record)}
          >
            {text}
          </div>
        );
      },
    },
    {
      title: "PENGIRIM",
      key: "",
      dataIndex: ["metaBuyer", "name"],
      render: (text: string, record: TransactionProps) => {
        return (
          <div>
            <Text className="text-3.5 text-primary-black block">
              {record.senderName}
            </Text>
            <div className="flex items-center gap-x-2">
              <Text className="text-3 capitalize text-gray-dark">
                {record.senderCarType}
              </Text>
              <Text className="text-3 capitalize text-gray-dark">
                {record.senderPlateNumber.toUpperCase()}
              </Text>
            </div>
          </div>
        );
      },
    },
    {
      title: "BERAT KOTOR (KG)",
      key: "grossWeight",
      align: "right" as "right",
      dataIndex: "grossWeight",
      render: (text: string, record: TransactionProps, index: number) => {
        return (
          <Text className="text-3.5 text-primary-black block text-right">
            {record.grossWeight}
          </Text>
        );
      },
    },
    {
      title: "BERAT KOSONG (KG)",
      key: "emptyWeight",
      align: "right" as "right",
      dataIndex: "emptyWeight",
      render: (text: string, record: TransactionProps, index: number) => {
        return (
          <Text className="text-3.5 text-primary-black block text-right">
            {record.emptyWeight}
          </Text>
        );
      },
    },
    {
      title: "BERAT BERSIH (KG)",
      key: "netWeight",
      align: "right" as "right",
      dataIndex: "netWeight",
      render: (text: string, record: TransactionProps, index: number) => {
        return (
          <Text className="text-3.5 text-primary-black block text-right">
            {record.netWeight}
          </Text>
        );
      },
    },
    {
      title: "TOTAL (RP)",
      key: "totalPrice",
      align: "right" as "right",
      dataIndex: "totalPrice",
      render: (text: string, record: TransactionProps, index: number) => {
        return (
          <Text className="text-3.5 text-primary-black block text-right">
            {formatPrice(record.totalPrice)}
          </Text>
        );
      },
    },
    {
      title: "ACTION",
      align: 'center' as 'center',
      key: "download",
      dataIndex: "download",
      render: (text: string, record: TransactionProps, index: number) => {
        return (
          <Row>
            {/* <Col span={8}>
              <BlobProvider
                document={<MyDocument transactions={[{ ...record }]} />}
              >
                {({ blob, url, loading, error }) => {
                  // if (loading) return <div>Loading...</div>;
                  // if (error) return <div>Error...</div>;
                  return (
                    <Button
                      // style={{
                      //   border: "none",
                      // }}
                      type="text"
                      onClick={() => printPDFHandler(blob)}
                    >
                      <IconPrint fontSize={12}/>
                    </Button>
                  );
                }}
              </BlobProvider>
            </Col> */}
            <Col span={8}>
              <PDFDownloadLink
                document={<MyDocument transactions={[{ ...record }]} />}
                fileName={`${record.transactionId}.pdf`}
              >
                <Button type="text">
                  {/* this icon to blue */}
                  <IconExportPDF />
                </Button>
              </PDFDownloadLink>
            </Col>
            <Col span={8}>
              <Popconfirm
                title="Anda yakin Menghapus Transaksi?"
                icon={<ExclamationCircleOutlined style={{ color: "red" }} />}
                placement="left"
                onConfirm={() => handleDelete(record.transactionId)}
                // onCancel={cancel}
                okText="Yes"
                cancelText="No"
              >
                <Button type="text">
                  <DeleteOutlined style={{ color: "red", fontSize:18, padding: "3px 0" }} />
                </Button>
              </Popconfirm>
            </Col>
          </Row>
        );
      },
    },
  ];

  const items = [
    {
      label: "Pengeluaran",
      key: "pengeluaran",
      children: (
        <ChartDashboard
          transactions={data}
          filterType={resFilterType}
          resFilterDate={resFilterDate}
        />
      ),
    },
    {
      label: "Berat Jagung",
      key: "berat",
      children: (
        <ChartDashboardCornWeight
          transactions={data}
          filterType={resFilterType}
          resFilterDate={resFilterDate}
        />
      ),
    },
    {
      label: "Transaksi",
      key: "transaksi",
      children: (
        <ChartDashboardTransactions
          transactions={data}
          filterType={resFilterType}
          resFilterDate={resFilterDate}
        />
      ),
    },
  ];
  return (
    <React.Fragment>
      {/* <HeaderSection
        title="Dashboard"
      /> */}
      <div
        style={{ padding: "24px 24px 48px 24px", backgroundColor: "#F4F6F9" }}
      >
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Card
              className="transaction-card"
              style={{
                border: "1px solid #E8ECF3",
                boxShadow:
                  "0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)",
              }}
            >
              <Row>
                <Col
                  span={24}
                  style={{
                    padding: "10px 20px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <WrapperFilter>
                    <div className="filter-item">
                      <Text className="title">Tipe Filter</Text>
                      <Select
                        // defaultValue={filterType}
                        value={filterType}
                        style={{ width: 150 }}
                        options={[
                          { value: "time_span", label: "Rentang Waktu" },
                          { value: "month", label: "Bulan" },
                          { value: "year", label: "Tahun" },
                        ]}
                        bordered={false}
                        suffixIcon={<IconChevron />}
                        onChange={handleChageFilterType}
                      />
                    </div>
                    <Divider type="vertical" style={{ margin: "0 10px" }} />
                    {filterType === "time_span" || filterType === "" ? (
                      <>
                        <div className="filter-item">
                          <Text className="title">Tanggal Awal</Text>
                          <DatePicker
                            locale={locale}
                            bordered={false}
                            suffixIcon={<IconChevron />}
                            style={{ width: 150 }}
                            format={"DD MMM YYYY"}
                            onChange={(date, dateString) => {
                              const startDate = moment(date).format();
                              setDateFilter({
                                ...dateFilter,
                                startAt: startDate,
                              });
                              setDateFilterTimeSpan({
                                ...dateFilterTimeSpan,
                                startAt: startDate,
                              });
                            }}
                            value={
                              getDateFormat(dateFilterTimeSpan.startAt) as any
                            }
                          />
                        </div>{" "}
                        <Divider type="vertical" style={{ margin: "0 10px" }} />
                        <div className="filter-item">
                          <Text className="title">Tanggal Akhir</Text>
                          <DatePicker
                            locale={locale}
                            bordered={false}
                            suffixIcon={<IconChevron />}
                            format={"DD MMM YYYY"}
                            style={{ width: 150 }}
                            onChange={(date, dateString) => {
                              const endDate = moment(date).format();
                              setDateFilter({
                                ...dateFilter,
                                endAt: endDate,
                              });
                              setDateFilterTimeSpan({
                                ...dateFilterTimeSpan,
                                endAt: endDate,
                              });
                            }}
                            value={
                              getDateFormat(dateFilterTimeSpan.endAt) as any
                            }
                          />
                        </div>
                      </>
                    ) : filterType === "month" ? (
                      <>
                        <div className="filter-item">
                          <Text className="title">Pilih Bulan</Text>
                          <DatePicker
                            // locale={locale}
                            placeholder="Pilih Bulan"
                            bordered={false}
                            suffixIcon={<IconChevron />}
                            style={{ width: 120 }}
                            format={"MMM YYYY"}
                            picker="month"
                            onChange={(date, dateString) => {
                              const startDate = moment(date?.format())
                                .startOf("month")
                                .format();
                              const endDate = moment(date?.format())
                                .endOf("month")
                                .format();
                              setDateFilter({
                                startAt: startDate,
                                endAt: endDate,
                              });
                              setDateFilterMonth(moment(date).format());
                            }}
                            value={getDateFormat(dateFilterMonth) as any}
                          />
                        </div>{" "}
                      </>
                    ) : filterType === "year" ? (
                      <>
                        <div className="filter-item">
                          <Text className="title">Pilih Tahun</Text>
                          <DatePicker
                            // locale={locale}
                            placeholder="Pilih Tahun"
                            bordered={false}
                            suffixIcon={<IconChevron />}
                            style={{ width: 120 }}
                            format={"YYYY"}
                            picker="year"
                            onChange={(date, dateString) => {
                              const startDate = moment(date?.format())
                                .startOf("year")
                                .format();
                              const endDate = moment(date?.format())
                                .endOf("year")
                                .format();
                              setDateFilter({
                                startAt: startDate,
                                endAt: endDate,
                              });
                              setDateFilterYear(moment(date).format());
                            }}
                            value={getDateFormat(dateFilterYear) as any}
                          />
                        </div>{" "}
                      </>
                    ) : null}
                  </WrapperFilter>
                  <Space>
                    <Button
                      type="primary"
                      onClick={handleDateFilter}
                      loading={isLoading}
                    >
                      Terapkan Filter
                    </Button>
                    <Button
                      onClick={resetFilter}
                      loading={isLoading}
                      danger
                      icon={<ReloadOutlined />}
                    >
                      Reset Filter
                    </Button>
                  </Space>
                </Col>
                <Col span={8}>
                  <TransactionItemSummary
                    title="Total Pengeluaran"
                    icon={<IconShop />}
                    sup="Rp"
                    total={totalExpenses}
                  />
                </Col>
                <Col span={8}>
                  <TransactionItemSummary
                    title="Total Berat Jagung"
                    icon={<IconWeight />}
                    sup="Kg"
                    total={totalCornWeight}
                  />
                </Col>
                <Col span={8}>
                  <TransactionItemSummary
                    title="Total Transaksi"
                    icon={<IconMenu />}
                    total={totalTransactions}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={24} style={{ marginBottom: 12 }}>
            <Text
              className="text-5 font-semibold text-primary-black block"
              style={{ marginBottom: 20 }}
            >
              {resFilterType === "time_span"
                ? "Data Pengeluaran, Berat dan Transaksi"
                : resFilterType === "month"
                ? `Data Statistik (Bulan ${moment(
                    resFilterDate?.startAt
                  ).format("MMM YYYY")})`
                : resFilterType === "year"
                ? `Data Statistik (Tahun ${moment(
                    resFilterDate?.startAt
                  ).format("YYYY")})`
                : "Data Pengeluaran, Berat dan Transaksi"}
            </Text>
            <CardStatistik bordered={false}>
              <Tabs
                onChange={(e) => {
                  setTypeTab(e);
                }}
                items={items}
                tabBarExtraContent={
                  <div className="flex items-center gap-x-2">
                    <IconExtra />{" "}
                    <Text style={{ color: "#768499", fontSize: 12 }}>
                      {/* {resFilterType === 'time_span'
                        ? `${
                            typeTab === 'berat'
                              ? 'Berat Jagung'
                              : typeTab === 'transaksi'
                              ? 'Transaksi'
                              : 'Pengeluaran'
                          }`
                        : resFilterType === 'month'
                        ? `${
                            typeTab === 'berat'
                              ? 'Berat Jagung'
                              : typeTab === 'transaksi'
                              ? 'Transaksi'
                              : 'Pengeluaran'
                          } ${moment(resFilterDate?.startAt).format(
                            'MMM YYYY'
                          )}`
                        : resFilterType === 'year'
                        ? `${
                            typeTab === 'berat'
                              ? 'Berat Jagung'
                              : typeTab === 'transaksi'
                              ? 'Transaksi'
                              : 'Pengeluaran'
                          } ${moment(resFilterDate?.startAt).format('YYYY')}`
                        : 'Pengeluaran'} */}
                      {typeTab === "berat"
                        ? "Berat Jagung"
                        : typeTab === "transaksi"
                        ? "Transaksi"
                        : "Pengeluaran"}{" "}
                      {resFilterType === "month"
                        ? moment(resFilterDate?.startAt).format("MMM YYYY")
                        : resFilterType === "year"
                        ? moment(resFilterDate?.startAt).format("YYYY")
                        : ""}
                    </Text>
                  </div>
                }
              />
            </CardStatistik>
          </Col>
          <Col span={24}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Text className="text-5 font-semibold text-primary-black">
                Daftar Transaksi Baru
              </Text>
              <Link
                to={"/transactions"}
                className="font-semibold flex items-center gap-x-2"
                style={{ color: "#188007" }}
              >
                Lihat semua{" "}
                <IconChevron
                  style={{ transform: "rotate(-90deg)", marginTop: 2 }}
                />
              </Link>
            </div>
          </Col>
          <Col span={24}>
            <Table
              loading={isLoading}
              size="small"
              columns={columns}
              dataSource={data.slice(0, 9)}
              rowClassName={(record, index) =>
                index % 2 === 0 ? "table-row-light" : "table-row-dark"
              }
              pagination={false}
            />
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;

const CardStatistik = styled(Card)`
  background-color: #fff;
  .ant-card-body {
    padding: 12px 20px 20px;
    border: 1px solid #e8ecf3;
    box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1),
      0px 2px 4px -1px rgba(0, 0, 0, 0.06);
    border-radius: 3px;
  }

  .ant-tabs-tab {
    padding: 14px;
    margin-left: 0;
  }
  .ant-tabs-tab.ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      color: #1e1e1e !important;
    }
  }
`;

const WrapperFilter = styled.div`
  display: flex;
  align-items: center;

  .filter-item {
    /* width: 300px; */
    display: flex;
    /* justify-content: space-evenly; */
    align-items: center;

    .ant-select-selection-item {
      font-size: 12px !important;
      color: #1e1e1e;
    }
    .ant-picker-input input {
      font-size: 12px !important;
      color: #1e1e1e;
    }
    .title {
      font-size: 10px;
      color: ${theme.colors.grayDark};
      display: block;
    }
  }
`;
