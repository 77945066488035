export type EUserRole =
  | 'admin'
  | 'supervisor_admin'
  | 'staff_admin'
  | 'customer'
  | ''
  
export type EUserStatus =
  | 'active'
  | 'inactive'

export type UserProperties = {
  userId: string;
  userType: EUserRole;
  name: string;
  email: string;
  phone?: string;
  birthdate?: Date;
  birthcity?: string;
  gender?: 'male'|'female';
  province?: string;
  city?: string;
  address?: string;
  profilePic?: string;
  status: EUserStatus;
  updatedAt?: Date;
  createdAt?: Date;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  nickName?: string;
}

export interface ICreateUser extends UserProperties {
  password?: string;
}

export type IChangePassword = {
  oldPassword: '',
  newPassword: '',
  retypePassword: ''
}
type IRoleList  = {
  [key: string]: string;
}
export const RoleList: IRoleList = {
  admin: 'Super Admin',
  staff_admin: 'Admin Staff',
  supervisor_admin: 'Admin Supervisor',
  customer: 'Customer'
}

export const initialUser: UserProperties = {
    userId: '',
    name: '',
    email: '',
    phone: '',
    gender: 'male',
    province: '',
    city: '',
    status: 'inactive',
    userType: 'customer',
    firstName: '',
    middleName: '',
    lastName: ''
}
