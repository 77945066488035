import { InfoCircleOutlined } from "@ant-design/icons";
import {
  Input,
  Typography,
  DatePicker,
  Button,
  Space,
  Row,
  Col,
  Card,
  Divider,
  Radio,
  InputNumber,
  Checkbox,
  Form,
  List,
  Modal,
  message,
} from "antd";
import { arrowIcon as IconArrow } from "../../assets/icons";
import HeaderSection from "../../components/HeaderSection";
import useFetchList from "../../hooks/useFetchList";
import React, { useEffect, useState } from "react";

import {
  ICreateTransaction,
  ITransactionMoistureContentCreateItem,
  TransactionProps,
  initialTransactions,
} from "../../types/transaction";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { theme } from "../../assets/theme";
import Section from "../../components/Section";
import Meta from "antd/lib/card/Meta";
import Link from "antd/lib/typography/Link";
import { MoistureContentProps } from "../../types/moistureContent.type";
import { priceFormat } from "../../helpers/priceFormat";
import { httpRequest } from "../../helpers/api";
import Transaction from ".";

export function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
interface ModalFormProps {
  open: boolean;
  onCreate: (values: string) => void;
  onCancel: () => void;
  title: string;
  defaultValue: string;
}

const { Text } = Typography;

const { RangePicker } = DatePicker;

const CreateTransaction = () => {
  const [transactionProperties, setTransactionProperties] =
    useState<ICreateTransaction>({
      ...initialTransactions,
      transactionMoistureContents:
        initialTransactions.TransactionMoistureContents,
    });
  const [totalGrossWeight, setTotalGrossWeight] = useState<number>(0);
  const [moistureContent, setMoistureContent] = useState<
    ITransactionMoistureContentCreateItem[]
  >([]);
  const [moistureContentTotalWeight, setMoistureContentTotalWeight] =
    useState<number>(0);
  const [isDeadSeedActive, setIsDeadSeedActive] = useState<boolean>(false);
  const [isLimeSeedActive, setIsLimeSeedActive] = useState<boolean>(false);
  const [isModalConfirmationShow, setIsModalConfirmationShow] =
    useState<boolean>(false);
  const [isModalDeadSeedShow, setIsModalDeadSeedShow] =
    useState<boolean>(false);
  const [isModalLimeSeedShow, setIsModalLimeSeedShow] =
    useState<boolean>(false);
  const [isDraft, setIsDraft] = useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage();

  const [form] = Form.useForm<TransactionProps>();
  const {
    isLoading,
    data,
    pagination,
    // setData,
    setSearch,
    setQuery,
    changePage,
    changeLimit,
    // setIsLoading,
  } = useFetchList<MoistureContentProps>({
    endpoint: "moistureContent",
    initialQuery: {
      isActive: true,
    },
  });

  const setSenderName = (value: any) => {
    setTransactionProperties({
      ...transactionProperties,
      senderName: value,
    });
  };

  const setNetWeight = (value: any) => {
    const valueNum = value ?? 0;
    setTransactionProperties({
      ...transactionProperties,
      netWeight: valueNum,
    });
  };

  const setNetPrice = (value: any) => {
    const valueNum = value ? parseInt(value) : 0;
    setTransactionProperties({
      ...transactionProperties,
      netCornPrice: valueNum,
    });
  };

  const setSenderPlateNumber = (value: any) => {
    setTransactionProperties({
      ...transactionProperties,
      senderPlateNumber: value,
    });
  };

  const setSenderCarType = (value: any) => {
    setTransactionProperties({
      ...transactionProperties,
      senderCarType: value,
    });
  };

  const setGrossWeight = (value: any) => {
    const valueNum = value ? parseInt(value) : 0;
    setTransactionProperties({
      ...transactionProperties,
      grossWeight: valueNum,
    });
  };

  const setEmptyWeight = (value: any) => {
    const valueNum = value ? parseInt(value) : 0;
    setTransactionProperties({
      ...transactionProperties,
      emptyWeight: valueNum,
    });
  };

  const setMoistureContentWeight = (value: any, moistureContentId: any) => {
    const valueNum = value ? parseInt(value) : 0;
    const newMoistureContent = moistureContent.map((item) => {
      if (item.moistureContentId === moistureContentId) {
        return {
          ...item,
          seedWeight: valueNum,
        };
      }
      return item;
    });
    setMoistureContent(newMoistureContent);

    const totalWeight = newMoistureContent.reduce((acc, item) => {
      const moistureContentData = data.find(
        (dataItem) => dataItem.moistureContentId === item.moistureContentId
      );
      return (
        acc +
        Math.floor(
          (item.seedWeight * (100 - (moistureContentData?.percentage || 0))) /
            100
        )
      );
    }, 0);

    let isDraftValue = true;
    if (transactionProperties.grossWeight) {
      isDraftValue = !(
        transactionProperties.grossWeight - transactionProperties.emptyWeight ==
        newMoistureContent.reduce((a, b) => a + b.seedWeight, 0)
      );
    }
    setIsDraft(isDraftValue);

    setNetWeight(totalWeight);
  };

  const setBriLink = (value: any) => {
    setTransactionProperties({
      ...transactionProperties,
      briLink: value,
    });
  };

  // const setDeadSeedPrice = (value: any) => {
  //   const valueNum = value ? parseInt(value) : 0;
  //   setTransactionProperties({
  //     ...transactionProperties,
  //     deadSeedPrice: valueNum,
  //   });
  // };

  // const setDeadSeedStatus = (value: any) => {
  //   setIsDeadSeedActive(value.target.checked);
  //   // reset dead seed price if unchecked
  //   if (!value.target.checked) {
  //     setDeadSeedPrice(0);
  //   }
  // };

  // const setDeadSeedComment = (value: any) => {
  //   setTransactionProperties({
  //     ...transactionProperties,
  //     deadSeedComment: value,
  //   });
  // };

  // const setLimeSeedPrice = (value: any) => {
  //   const valueNum = value ? parseInt(value) : 0;
  //   setTransactionProperties({
  //     ...transactionProperties,
  //     limeSeedPrice: valueNum,
  //   });
  // };

  // const setLimeSeedComment = (value: any) => {
  //   setTransactionProperties({
  //     ...transactionProperties,
  //     limeSeedComment: value,
  //   });
  // };

  // const setLimeSeedStatus = (value: any) => {
  //   setIsLimeSeedActive(value.target.checked);
  //   // reset lime seed price if unchecked
  //   if (!value.target.checked) {
  //     setLimeSeedPrice(0);
  //   }
  // };

  const showConfirmationModal = () => {
    setIsModalConfirmationShow(true);
  };

  const hideConfirmationModal = () => {
    setIsModalConfirmationShow(false);
  };

  // const showDeadSeedModal = () => {
  //   setIsModalDeadSeedShow(true);
  // };

  // const hideDeadSeedModal = () => {
  //   setIsModalDeadSeedShow(false);
  // };

  // const showLimeSeedModal = () => {
  //   setIsModalLimeSeedShow(true);
  // };

  // const hideLimeSeedModal = () => {
  //   setIsModalLimeSeedShow(false);
  // };

  const sendData = async (values: any) => {
    // post to /transaction
    transactionProperties.transactionMoistureContents = moistureContent;
    try {
      // const await httpRequest.post("/transactions", transactionProperties);
      const response = await httpRequest.post(
        "/transactions",
        transactionProperties
      );
      if (response.status === 201) {
        hideConfirmationModal();
        // reload page
        window.location.reload();
      } else {
        console.log("error");
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setMoistureContent(
      data.map((item) => {
        const oldData = moistureContent.find((moistureContentItem) => {
          if (moistureContentItem.moistureContentId === item.moistureContentId)
            return moistureContentItem;
        });

        return {
          moistureContentId: item.moistureContentId,
          seedWeight: oldData?.seedWeight || 0,
        };
      })
    );
    setTransactionProperties({
      ...transactionProperties,
      transactionMoistureContents: moistureContent,
    });
  }, [data]);

  useEffect(() => {
    setTotalGrossWeight(
      transactionProperties.grossWeight - transactionProperties.emptyWeight
    );
    let isDraftValue = true;
    if (transactionProperties.grossWeight) {
      isDraftValue = !(
        transactionProperties.grossWeight - transactionProperties.emptyWeight ==
        moistureContent.reduce((a, b) => a + b.seedWeight, 0)
      );
    }
    setIsDraft(isDraftValue);
    // console.log("transaction gross - empty", transactionProperties.grossWeight - transactionProperties.emptyWeight)
    // console.log("moistureWeight", moistureContent.reduce(
    //   (a, b) => a + b.seedWeight,
    //   0
    // ))
    console.log(isDraft);
  }, [transactionProperties.emptyWeight, transactionProperties.grossWeight]);

  useEffect(() => {
    setMoistureContentTotalWeight(
      moistureContent.reduce((acc, item) => acc + item.seedWeight, 0)
    );

    setTransactionProperties({
      ...transactionProperties,
      transactionMoistureContents: moistureContent,
    });
  }, [moistureContent]);

  useEffect(() => {
    setTransactionProperties({
      ...transactionProperties,
      totalPrice:
        transactionProperties.netWeight * transactionProperties.netCornPrice -
        (transactionProperties.briLink || 0),
    });
  }, [
    transactionProperties.netCornPrice,
    transactionProperties.briLink,
    transactionProperties,
  ]);

  return (
    <React.Fragment>
      <Form form={form} onFinish={showConfirmationModal} scrollToFirstError>
        <HeaderSection
          icon={"back"}
          title="Buat Transaksi"
          rightAction={
            <Space>
              <Button style={{ backgroundColor: "white" }}>Batal</Button>
              <Button type="primary" htmlType="submit">
                Simpan
              </Button>
            </Space>
          }
        />
        <div style={{ margin: "24px", paddingBottom: "24px" }}>
          <Section
            title="IDENTITAS PENGIRIM"
            subtitle="Berikut adalah identitas nama , jenis mobil, dan plat nomornya"
          >
            <Row gutter={[12, 12]}>
              <Col span={24}>
                <Text style={{ color: "#768499" }}>Nama </Text>
                <Form.Item name={"senderName"}>
                  <Input
                    required
                    placeholder="Masukkan Nama"
                    onChange={(value) =>
                      setSenderName(value.currentTarget.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Text style={{ color: "#768499" }}>Jenis Mobil </Text>
                <br />
                <Form.Item name={"senderCarType"}>
                  <Radio.Group
                    defaultValue={1}
                    size="large"
                    buttonStyle="outline"
                    style={{ margin: "auto 0px", verticalAlign: "middle" }}
                  >
                    <Radio
                      value={1}
                      onClick={(value) => setSenderCarType("truck")}
                    >
                      Truck
                    </Radio>
                    <Radio
                      value={2}
                      onClick={(value) => setSenderCarType("pickup")}
                    >
                      Pickup
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Text style={{ color: "#768499" }}>Plat Nomor </Text>
                <Form.Item name={"senderPlateNumber"}>
                  <Input
                    required
                    placeholder="B 1212 SR"
                    onChange={(value) =>
                      setSenderPlateNumber(value.currentTarget.value)
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          </Section>
          <Divider />
          <Section
            title="BERAT"
            subtitle="Berikut adalah perhitungan berat jagung kotor. Berat Jagung Kotor = Berat Kotor - Berat Kosong"
          >
            <Row gutter={[12, 12]}>
              <Col span={24} style={{ padding: "9px 8px" }}>
                <Row>
                  <Col style={{ margin: "auto 0" }} span={5}>
                    <Text style={{ color: "#768499" }}>Berat Kotor </Text>
                  </Col>
                  <Col span={3} style={{ margin: "auto 0" }}>
                    <Form.Item
                      style={{ margin: "auto 0" }}
                      name={"grossWeight"}
                    >
                      <InputNumber
                        required
                        addonAfter="Kg"
                        controls={false}
                        onChange={(value) => setGrossWeight(value)}
                        defaultValue={0}
                      />
                    </Form.Item>
                  </Col>
                  <Col style={{ margin: "auto 0", padding: "15px" }} span={12}>
                    <Text style={{ color: "#768499" }}>
                      Berat Barang + Pickup/Truck + (Berat Kotor)
                    </Text>
                  </Col>
                  <Col
                    style={{ margin: "auto 0", textAlign: "right" }}
                    span={4}
                  >
                    <Text strong style={{ fontSize: "14px" }}>
                      {transactionProperties.grossWeight} Kg
                    </Text>
                  </Col>
                </Row>
              </Col>
              <Col span={24} style={{ padding: "9px 8px" }}>
                <Row>
                  <Col style={{ margin: "auto 0" }} span={5}>
                    <Text style={{ color: "#768499" }}>Berat Kosong </Text>
                  </Col>
                  <Col style={{ margin: "auto 0" }} span={3}>
                    <Form.Item
                      style={{ margin: "auto 0" }}
                      name={"emptyWeight"}
                    >
                      <InputNumber
                        required
                        addonAfter="Kg"
                        controls={false}
                        max={transactionProperties.grossWeight}
                        onChange={(value) => setEmptyWeight(value)}
                        defaultValue={0}
                      />
                    </Form.Item>
                  </Col>
                  <Col style={{ margin: "auto 0", padding: "15px" }} span={12}>
                    <Text style={{ color: "#768499" }}>
                      Pickup/Truck + (Berat Kotor)
                    </Text>
                  </Col>
                  <Col
                    style={{ margin: "auto 0", textAlign: "right" }}
                    span={4}
                  >
                    <Text strong style={{ fontSize: "14px", color: "#FE4545" }}>
                      ({transactionProperties.emptyWeight} Kg)
                    </Text>
                  </Col>
                </Row>
              </Col>
              <Col style={{ padding: 0 }} span={24}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "9px 8px",
                    backgroundColor: "#E8ECF3",
                    width: "100%",
                  }}
                >
                  <Text strong>Berat Jagung Kotor </Text>
                  <Text strong style={{ fontSize: "14px" }}>
                    {totalGrossWeight} Kg
                  </Text>
                </div>
              </Col>
            </Row>
          </Section>
          <Divider />
          <Section
            title="KADAR AIR"
            subtitle="Berikut adalah kadar air.
            Kadar Air diisi dengan jumlah kadar air pada jagung dari total berat jagung kotor"
          >
            {/* check if total totalGrossWeight - moistureContentTotalWeight is not 0 */}
            {totalGrossWeight - moistureContentTotalWeight !== 0 && (
              <Card
                className="card-no-padding"
                style={
                  totalGrossWeight - moistureContentTotalWeight > 0
                    ? {
                        padding: "15px",
                        borderRadius: "3px",
                        backgroundColor: "#FFF8E4",
                        border: "none",
                        marginBottom: "12px",
                      }
                    : {
                        padding: "15px",
                        borderRadius: "3px",
                        backgroundColor: "#FFF2F0",
                        border: "none",
                        marginBottom: "12px",
                      }
                }
              >
                <Meta
                  avatar={
                    <InfoCircleOutlined
                      style={
                        totalGrossWeight - moistureContentTotalWeight > 0
                          ? { color: "#FFBC00" }
                          : { color: "#FF4D4F" }
                      }
                    />
                  }
                  description={
                    <Text>
                      Sisa Berat Jagung Kotor:{" "}
                      <strong>
                        {totalGrossWeight - moistureContentTotalWeight}kg
                      </strong>
                      ,
                      {totalGrossWeight - moistureContentTotalWeight > 0
                        ? `total berat untuk kadar air tidak boleh kurang dari${" "}`
                        : `total berat untuk kadar air tidak boleh melebihi${" "}`}
                      <strong>{totalGrossWeight}kg</strong>
                    </Text>
                  }
                />
              </Card>
            )}

            <Row gutter={[12, 12]}>
              <Col span={24} style={{ padding: "0px 8px" }}>
                <List
                  dataSource={data}
                  renderItem={(item, index) => {
                    const MoistureContentWeight =
                      moistureContent.find(
                        (x) => x.moistureContentId === item.moistureContentId
                      )?.seedWeight || 0;
                    return (
                      <Row>
                        <Col style={{ margin: "auto 0" }} span={5}>
                          <Text style={{ color: "#768499" }}>
                            {item.minimumLimit} - {item.maximumLimit}%{" "}
                          </Text>
                        </Col>
                        <Col style={{ margin: "auto 0" }} span={3}>
                          <InputNumber
                            addonAfter="Kg"
                            controls={false}
                            width={"100%"}
                            onChange={(value) =>
                              setMoistureContentWeight(
                                value,
                                item.moistureContentId
                              )
                            }
                            defaultValue={0}
                          />
                        </Col>
                        <Col
                          style={{ margin: "auto 0", padding: "15px" }}
                          span={1}
                        >
                          <Text style={{ color: "#768499" }}>x</Text>
                        </Col>
                        <Col style={{ margin: "auto 0" }} span={10}>
                          <Text style={{ color: "#768499" }}>
                            Potongan {item.percentage}%
                          </Text>
                        </Col>
                        <Col
                          style={{ margin: "auto 0", textAlign: "right" }}
                          span={5}
                        >
                          <Text strong style={{ fontSize: "14px" }}>
                            {Math.floor(
                              (MoistureContentWeight *
                                (100 - item.percentage)) /
                                100
                            )}{" "}
                            Kg
                          </Text>
                        </Col>
                      </Row>
                    );
                  }}
                />
              </Col>
              <Col style={{ padding: 0 }} span={24}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "9px 8px",
                    backgroundColor: "#E8ECF3",
                  }}
                >
                  <Text strong>Berat Jagung Bersih </Text>
                  <Text strong style={{ fontSize: "14px" }}>
                    {transactionProperties.netWeight} Kg
                  </Text>
                </div>
              </Col>
            </Row>
          </Section>
          <Divider />
          <Section
            title="RINGKASAN TRANSAKSI"
            subtitle="Berikut adalah total pengeluaran dari pengurangan Harga Jagung Bersih dengan Total Potongan."
            style={{ backgroundColor: "#F4F6F9" }}
          >
            <Row gutter={[12, 12]}>
              <Col span={24} style={{ padding: "0px 8px" }}>
                <Row>
                  <Col style={{ margin: "auto 0" }} span={5}>
                    <Text>Harga Jagung Bersih</Text>
                  </Col>
                  <Col style={{ margin: "auto 0", padding: "15px" }} span={3}>
                    <Text style={{ color: "#768499" }}>
                      {transactionProperties.netWeight} kg
                    </Text>
                  </Col>
                  <Col style={{ margin: "auto 0" }} span={1}>
                    <Text style={{ color: "#768499" }}>x</Text>
                  </Col>
                  <Col style={{ margin: "auto 0" }} span={4}>
                    <InputNumber
                      required
                      addonBefore="Rp"
                      controls={false}
                      onChange={(value) => setNetPrice(value)}
                      defaultValue={0}
                    />
                  </Col>
                  <Col span={7}></Col>
                  <Col
                    style={{
                      margin: "auto 0",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    span={4}
                  >
                    <Text strong style={{ fontSize: "14px" }}>
                      Rp
                    </Text>
                    <Text strong style={{ fontSize: "14px" }}>
                      {priceFormat(
                        transactionProperties.netWeight *
                          transactionProperties.netCornPrice
                      ).substring(3)}
                    </Text>
                  </Col>
                </Row>
              </Col>
              {/* <Col span={24} style={{ padding: "0px 8px" }}>
                <Row gutter={[0, 10]}>
                  <Col style={{ margin: "auto 0" }} span={5}>
                    <Checkbox onChange={(value) => setDeadSeedStatus(value)}>
                      Biji Mati
                    </Checkbox>
                  </Col>
                  <Col
                    style={{ margin: "auto 0", padding: "15px" }}
                    span={3}
                  ></Col>
                  <Col style={{ margin: "auto 0" }} span={1}></Col>
                  <Col style={{ margin: "auto 0" }} span={4}>
                    <InputNumber
                      addonBefore="Rp"
                      controls={false}
                      disabled={!isDeadSeedActive}
                      onChange={(value) => setDeadSeedPrice(value)}
                      defaultValue={0}
                    />
                  </Col>
                  <Col style={{ margin: "auto 0", padding: "0 10px" }} span={7}>
                    <Text style={{ color: "#3F4C5F" }}>/Kg</Text>
                  </Col>
                  <Col
                    style={{
                      margin: "auto 0",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    span={4}
                  >
                    <Text strong style={{ fontSize: "14px", color: "#FE4545" }}>
                      (Rp
                    </Text>
                    <Text strong style={{ fontSize: "14px", color: "#FE4545" }}>
                      {priceFormat(
                        transactionProperties.deadSeedPrice
                          ? transactionProperties.deadSeedPrice *
                              transactionProperties.netWeight
                          : 0
                      ).substring(3)}
                      )
                    </Text>
                  </Col>
                  <Col style={{ margin: "auto 0" }} span={24}>
                    {isDeadSeedActive && (
                      <Space>
                        {transactionProperties.deadSeedComment ? (
                          <>
                            <IconArrow />
                            <Text>{transactionProperties.deadSeedComment}</Text>
                            <Link
                              strong
                              style={{ color: "#188007" }}
                              onClick={showDeadSeedModal}
                            >
                              Ubah
                            </Link>
                          </>
                        ) : (
                          <>
                            <IconArrow />
                            <Link
                              strong
                              style={{ color: "#188007" }}
                              onClick={showDeadSeedModal}
                            >
                              Tambah Catatan
                            </Link>
                          </>
                        )}
                      </Space>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col span={24} style={{ padding: "0px 8px" }}>
                <Row gutter={[0, 10]}>
                  <Col style={{ margin: "auto 0" }} span={5}>
                    <Checkbox onChange={(value) => setLimeSeedStatus(value)}>
                      Biji Kapur
                    </Checkbox>
                  </Col>
                  <Col
                    style={{ margin: "auto 0", padding: "15px" }}
                    span={3}
                  ></Col>
                  <Col style={{ margin: "auto 0" }} span={1}></Col>
                  <Col style={{ margin: "auto 0" }} span={4}>
                    <InputNumber
                      addonBefore="Rp"
                      controls={false}
                      disabled={!isLimeSeedActive}
                      onChange={(value) => setLimeSeedPrice(value)}
                      defaultValue={0}
                    />
                  </Col>
                  <Col style={{ margin: "auto 0", padding: "0 10px" }} span={7}>
                    <Text style={{ color: "#3F4C5F" }}>/Kg</Text>
                  </Col>
                  <Col
                    style={{
                      margin: "auto 0",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    span={4}
                  >
                    <Text strong style={{ fontSize: "14px", color: "#FE4545" }}>
                      (Rp
                    </Text>
                    <Text strong style={{ fontSize: "14px", color: "#FE4545" }}>
                      {priceFormat(
                        transactionProperties.limeSeedPrice
                          ? transactionProperties.limeSeedPrice *
                              transactionProperties.netWeight
                          : 0
                      ).substring(3)}
                      )
                    </Text>
                  </Col>
                  <Col style={{ margin: "auto 0" }} span={24}>
                    {isLimeSeedActive && (
                      <Space>
                        {transactionProperties.limeSeedComment ? (
                          <>
                            <IconArrow />
                            <Text>{transactionProperties.limeSeedComment}</Text>
                            <Link
                              strong
                              style={{ color: "#188007" }}
                              onClick={showLimeSeedModal}
                            >
                              Ubah
                            </Link>
                          </>
                        ) : (
                          <>
                            <IconArrow />
                            <Link
                              strong
                              style={{ color: "#188007" }}
                              onClick={showLimeSeedModal}
                            >
                              Tambah Catatan
                            </Link>
                          </>
                        )}
                      </Space>
                    )}
                  </Col>
                </Row>
              </Col> */}
              <Col span={24} style={{ padding: "0px 8px" }}>
                <Row gutter={[0, 10]}>
                  <Col style={{ margin: "auto 0" }} span={5}>
                    Bri Link
                    {/* <Checkbox onChange={(value) => setLimeSeedStatus(value)}>
                      Biji Kapur
                    </Checkbox> */}
                  </Col>
                  <Col
                    style={{ margin: "auto 0", padding: "15px" }}
                    span={3}
                  ></Col>
                  <Col style={{ margin: "auto 0" }} span={1}></Col>
                  <Col style={{ margin: "auto 0" }} span={4}>
                    <InputNumber
                      addonBefore="Rp"
                      controls={false}
                      // disabled={!isLimeSeedActive}
                      onChange={(value) => setBriLink(value)}
                      defaultValue={0}
                    />
                  </Col>
                  <Col style={{ margin: "auto 0", padding: "0 10px" }} span={7}>
                    {/* <Text style={{ color: "#3F4C5F" }}>/Kg</Text> */}
                  </Col>
                  <Col
                    style={{
                      margin: "auto 0",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    span={4}
                  >
                    <Text strong style={{ fontSize: "14px", color: "#FE4545" }}>
                      (Rp
                    </Text>
                    <Text strong style={{ fontSize: "14px", color: "#FE4545" }}>
                      {priceFormat(transactionProperties.briLink).substring(3)})
                    </Text>
                  </Col>
                </Row>
              </Col>
              <Col style={{ padding: 0 }} span={24}>
                <Row style={{ padding: "9px 8px", backgroundColor: "#1E1E1E" }}>
                  <Col style={{ margin: "auto 0" }} span={20}>
                    <Text strong style={{ color: "#FFFFFF" }}>
                      Total Transaksi
                    </Text>
                  </Col>
                  <Col
                    style={{
                      margin: "auto 0",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    span={4}
                  >
                    <Text strong style={{ fontSize: "14px", color: "#FFFFFF" }}>
                      Rp
                    </Text>
                    <Text strong style={{ fontSize: "14px", color: "#FFFFFF" }}>
                      {transactionProperties.totalPrice
                        .toLocaleString("id-ID")
                        .replace(/,/g, ".")}
                    </Text>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Section>
        </div>
      </Form>

      <Modal
        title="Apakah anda yakin?"
        open={isModalConfirmationShow}
        onOk={sendData}
        onCancel={hideConfirmationModal}
        className="custom-modal"
        okText={isDraft ? "Ya, Simpan Draft" : "Ya, Simpan & Cetak"}
        cancelText="Batal"
      >
        <p style={{ paddingRight: 10 }}>
          {isDraft
            ? "Total berat bersih tidak sesuai, data transaksi akan disimpan sebagai draft. Apakah anda yakin ingin menyimpan data transaksi ini? "
            : "Apakah anda yakin ingin menyimpan data transaksi ini?. Pastikan semua input data sudah sesuai"}
        </p>
      </Modal>
      {/* <ModalForm
        title="Edit Catatan Biji Mati"
        open={isModalDeadSeedShow}
        onCancel={hideDeadSeedModal}
        onCreate={(value) => setDeadSeedComment(value)}
        defaultValue={transactionProperties.deadSeedComment || ""}
      />
      <ModalForm
        title="Edit Catatan Biji Kapur"
        open={isModalLimeSeedShow}
        onCancel={hideLimeSeedModal}
        onCreate={(value) => setLimeSeedComment(value)}
        defaultValue={transactionProperties.limeSeedComment || ""}
      /> */}
    </React.Fragment>
  );
};

const ModalForm: React.FC<ModalFormProps> = ({
  open,
  onCreate,
  onCancel,
  title,
  defaultValue,
}) => {
  const [form] = Form.useForm();
  return (
    <Modal
      open={open}
      title={title}
      okText="Simpan"
      cancelText="Batal"
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      onOk={() => {
        onCreate(form.getFieldValue("catatan"));
        form.resetFields();
        onCancel();
      }}
    >
      <Form form={form} layout="vertical" name="form_in_modal">
        <Form.Item name="catatan" label="Catatan">
          <Input defaultValue={defaultValue} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export const CustomRangePicker = styled(RangePicker)`
  border: 0px;
  border: 1px solid ${theme.colors.ash500};
  width: 344px;
`;

export default CreateTransaction;
