import { MoistureContentProps } from './moistureContent.type';
import { ProductProps } from './products.type';

export enum ESenderCarType {
	truck = 'truck',
	pickup = 'pickup',
}

export interface TransactionProps {
	transactionId: string;
	transactionCode?: string;
	isDraft?: boolean;
	senderName: string;
	senderCarType: ESenderCarType;
	senderPlateNumber: string;
	grossWeight: number;
	emptyWeight: number;
	netWeight: number;
	netCornPrice: number;
	// deadSeedPrice: number;
	// deadSeedComment?: string;
	// limeSeedPrice: number;
	// limeSeedComment?: string;
	briLink: number;
	totalPrice: number;
	TransactionMoistureContents: transactionMoistureContentItemProperties[];
	createdAt?: Date;
	updatedAt?: Date;
}

export interface transactionMoistureContentItemProperties {
	id: string;
	transactionId: string;
	moistureContentId: string;
	metaMoistureContent: MoistureContentProps;
	seedWeight: number;
	createdAt?: Date;
	updatedAt?: Date;
}

export interface ICreateTransaction {
	transactionId?: string;
	senderName: string;
	senderCarType: ESenderCarType;
	senderPlateNumber: string;
	grossWeight: number;
	emptyWeight: number;
	netWeight: number;
	netCornPrice: number;
	// deadSeedPrice?: number;
	// deadSeedComment?: string;
	// limeSeedPrice?: number;
	// limeSeedComment?: string;
	briLink?: number;
	totalPrice: number;
	transactionMoistureContents: ITransactionMoistureContentCreateItem[];
}

export interface ITransactionMoistureContentCreateItem {
	moistureContentId?: string;
	seedWeight: number;
}

// export interface TransactionItemProperties {
// 	transactionItemId: string;
// 	transactionId: string;
// 	productId: string;
// 	metaProduct: ProductProps;
// 	qty: number;
// 	unitOfMeasure: string;
// 	pricePerUnit: number;
// 	totalPrice: number;
// 	createdAt?: Date;
// 	updatedAt?: Date;
// 	transaction?: TransactionProps;
// }

// export interface TransactionProps {
// 	transactionId: string;
// 	transactionCode: string;
// 	totalFinalPrice: number;
// 	transactionStatus: ETransactionStatus;
// 	metaBuyer: any;
// 	createdAt?: Date;
// 	updatedAt?: Date;
// 	transactionItems?: TransactionItemProperties[];
// }

export const initialTransactions: TransactionProps = {
	transactionId: '',
	senderName: '',
	transactionCode: '',
	isDraft: true,
	senderCarType: ESenderCarType.truck,
	senderPlateNumber: '',
	grossWeight: 0,
	emptyWeight: 0,
	netWeight: 0,
	netCornPrice: 0,
	// deadSeedPrice: 0,
	// deadSeedComment: '',
	// limeSeedPrice: 0,
	// limeSeedComment: '',
	briLink: 0,
	totalPrice: 0,
	TransactionMoistureContents: []
};
