import {
  Table,
  Typography,
  Button,
  Modal,
  Row,
  Col,
  List,
  Input,
  Space,
  Form,
  InputNumber,
  message,
} from "antd";
import HeaderSection from "../../components/HeaderSection";
import React from "react";
import Section from "../../components/Section";
import { ColumnsType } from "antd/lib/table";
import useFetchList from "../../hooks/useFetchList";
import { MoistureContentProps } from "../../types/moistureContent.type";
import { DeleteOutlined, MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { httpRequest } from "../../helpers/api";
const { Text } = Typography;

const columns: ColumnsType<MoistureContentProps> = [
  {
    title: "Kadar Air",
    dataIndex: ["maximumLimit", "minimumLimit"],
    render: (text, record, index) => (
      <Text>{`${record.minimumLimit} - ${record.maximumLimit}%`}</Text>
    ),
  },
  {
    title: "Potongan",
    dataIndex: "percentage",
    render: (text: string) => <Text>{text}</Text>,
  },
];

const KadarAir = () => {
  const [form] = Form.useForm<MoistureContentProps[]>();

  const { isLoading, setData, data } = useFetchList<MoistureContentProps>({
    endpoint: "moistureContent",
    initialQuery: {
      isActive: true,
    },
  });
  const [moistureContentData, setMoistureContentData] = React.useState<
    MoistureContentProps[] | []
  >([]);
  const [isModalConfirmOpen, setIsModalConfirmOpen] = React.useState(false);
  const [isModalEditOpen, setIsModalEditOpen] = React.useState(false);
  const [isLoadingAction, setIsLoadingAction] = React.useState(false);

  React.useEffect(() => {
    setMoistureContentData(
      data.sort((a, b) => a.minimumLimit - b.minimumLimit)
    );
  }, [data]);

  const showModalEdit = () => {
    setIsModalEditOpen(true);
  };

  const hideModalEdit = () => {
    setIsModalEditOpen(false);
  };

  const showModalConfirm = () => {
    hideModalEdit();
    setIsModalConfirmOpen(true);
  };

  const hideModalConfirm = () => {
    setIsModalConfirmOpen(false);
    showModalEdit();
  };

  const addMoistureContent = () => {
    const isEmpty = moistureContentData.length === 0;
    const minimum = isEmpty
      ? 0
      : moistureContentData[moistureContentData.length - 1].maximumLimit + 0.1;
    setMoistureContentData([
      ...moistureContentData,
      {
        minimumLimit: Number(minimum.toFixed(1)),
        maximumLimit: Number((minimum + 0.1).toFixed(1)),
        percentage: 0,
        isActive: true,
      },
    ]);
  };

  const handleOnChangeMinimumLimit = (value: number | null, index: number) => {
    value = value || 0;
    if (value > moistureContentData[index].maximumLimit) {
      setMaximumLimit(value + 0.1, index);
    }
    setMinimumLimit(value, index);
  };

  const handleOnChangeMaximumLimit = (value: number, index: number) => {
    if (index !== moistureContentData.length - 1) {
      setMinimumLimit(value + 0.1, index + 1);
    }
    setMaximumLimit(value, index);
  };

  const handleOnChangePercentage = (value: number, index: number) => {
    setPercentage(value, index);
  };

  const handleOnDelete = () => {
    const newData = [...moistureContentData];
    newData.pop();
    setMoistureContentData(newData);
  };

  const setMinimumLimit = (value: number, index: number) => {
    if (value > moistureContentData[index].maximumLimit) {
      setMaximumLimit(value + 0.1, index);
    }
    const newData = [...moistureContentData];
    newData[index].minimumLimit = Number(value);
    setMoistureContentData(newData);
  };

  const setMaximumLimit = (value: number, index: number) => {
    const newData = [...moistureContentData];
    newData[index].maximumLimit = Number(value);
    setMoistureContentData(newData);
  };

  const setPercentage = (value: number, index: number) => {
    const newData = [...moistureContentData];
    newData[index].percentage = Number(value);
    setMoistureContentData(newData);
  };

  const handleOnSave = async () => {
    try {
      setIsLoadingAction(true);
      console.log({ bulk: moistureContentData });
      await httpRequest.patch("moistureContent/bulk", {
        bulk: moistureContentData,
      });
      message.success("Berhasil mengubah kadar air");
      setData(moistureContentData);
      setIsModalConfirmOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <React.Fragment>
      <HeaderSection
        title="Kadar Air"
        subtitle="Dasar perhitungan kadar air dan pengurangan"
        rightAction={
          <Button type="primary" onClick={showModalEdit}>
            Edit Kadar Air
          </Button>
        }
      />
      <div style={{ margin: "24px", paddingBottom: "24px" }}>
        <Section
          title="KADAR AIR"
          subtitle="Ini adalah daftar kadar air beserta potongannya. Kadar air akan dihitung secara otomatis saat memasukkan berat kotor yang telah diperoleh."
        >
          <Table
            columns={columns}
            dataSource={data}
            bordered
            pagination={false}
          />
        </Section>
      </div>
      <Modal
        title="Edit Kadar Air"
        open={isModalEditOpen}
        onCancel={hideModalEdit}
        onOk={showModalConfirm}
        width={600}
        className="custom-modal"
        okText="Simpan"
        cancelText="Batal"
      >
        <Row>
          <Col span={15}>
            <Text strong>Kadar Air</Text>
          </Col>
          <Col span={6}>
            <Text strong>Potongan</Text>
          </Col>
          <Col span={3}>
            <Text strong>Aksi</Text>
          </Col>
          <Col span={24}>
            <List
              dataSource={[...moistureContentData]}
              key={"moistureContentData"}
              loading={isLoading}
              footer={
                <Button
                  block
                  icon={<PlusOutlined style={{ color: "#1E1E1E" }} />}
                  onClick={() => addMoistureContent()}
                >
                  <Text strong>Tambah Kadar Air</Text>
                </Button>
              }
              renderItem={(item, index) => {
                const isLast = index === moistureContentData.length - 1;
                const isFirst = index === 0;
                const minimum = isFirst
                  ? 0
                  : moistureContentData[index - 1].maximumLimit + 0.2;
                return (
                  <Col span={24}>
                    <Row key={index} style={{ margin: "16px 0" }}>
                      <Col span={15} style={{ display: "flex" }}>
                        <Space split={<MinusOutlined />}>
                          <InputNumber
                            addonAfter="%"
                            style={{ width: 125 }}
                            disabled={index !== 0}
                            defaultValue={item.minimumLimit}
                            onChange={(value) => {
                              handleOnChangeMinimumLimit(value, index);
                            }}
                            value={
                              !isFirst
                                ? moistureContentData[index].minimumLimit
                                : null
                            }
                          />
                          <InputNumber
                            addonAfter="%"
                            style={{ width: 125 }}
                            defaultValue={item.maximumLimit}
                            min={minimum}
                            onChange={(value) =>
                              handleOnChangeMaximumLimit(
                                value || minimum,
                                index
                              )
                            }
                          />
                        </Space>
                      </Col>
                      <Col span={6} style={{ paddingRight: "20px" }}>
                        <InputNumber
                          addonAfter="%"
                          defaultValue={item.percentage}
                          onChange={(value) =>
                            handleOnChangePercentage(value || 0, index)
                          }
                          min={0}
                        />
                      </Col>
                      <Col span={3}>
                        <Button
                          type="link"
                          onClick={() => handleOnDelete()}
                          disabled={!isLast}
                        >
                          <DeleteOutlined
                            style={{
                              fontSize: 16,
                              color: isLast ? "#FE4545" : "#9FACBF",
                            }}
                          />
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                );
              }}
            />
          </Col>
        </Row>
      </Modal>
      <Modal
        title="Simpan Perubahan Kadar Air"
        open={isModalConfirmOpen}
        onCancel={hideModalConfirm}
        onOk={handleOnSave}
        className="custom-modal"
        okText="Ya, Simpan"
        cancelText="Batal"
      >
        <div style={{ margin: "32px 0" }}>
          <Text>Apakah anda yakin ingin menyimpan perubahan data ini?</Text>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default KadarAir;
