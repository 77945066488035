import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
  MoreOutlined,
  SearchOutlined,
  UserOutlined,
  // PlusOutlined,
} from "@ant-design/icons";
import {
  // Row,
  // Col,
  // Space,
  Table,
  // Switch,
  Modal,
  message,
  Input,
  Select,
  Dropdown,
  Menu,
  Tag,
  Button,
  Switch,
  // Card,
} from "antd";
import HeaderSection from "../../components/HeaderSection";
import { httpRequest } from "../../helpers/api";
import { BaseResponseProps } from "../../types/config.type";
import { UserProperties, initialUser, RoleList } from "../../types/user.type";
import useFetchList from "../../hooks/useFetchList";
import { formatDate } from "../../helpers/constant";
import Text from "antd/lib/typography/Text";
import { getErrorMessage } from "../../helpers/errorHandler";
import { showRoleName, getAdminRoles } from "../../helpers/auth";
import type { TableProps } from "antd";
import styled from "styled-components";
import { theme } from "../../assets/theme";
import useAuth from "../../hooks/useAuth";

interface ResponseProps
  extends BaseResponseProps<Omit<UserProperties, "createdAt" | "updatedAt">> {}

type Props = {
  userType?: "admin" | "customer";
};

const User = (props: Props) => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const [isLoadingUpdate, setIsLoadingUpdate] = React.useState(false);
  const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false);
  const [tmpData, setTmpData] = React.useState<UserProperties>(initialUser);
  const [userIds, setUserIds] = React.useState<string>();
  const [userIdsArr, setUserIdsArr] = React.useState<any[]>([]);

  const filterUserType = useMemo(
    () => (props.userType === "admin" ? getAdminRoles().join(",") : "customer"),
    [props.userType]
  );

  const {
    isLoading,
    data,
    pagination,
    query,
    setData,
    setSearch,
    setQuery,
    changePage,
  } = useFetchList<UserProperties>({
    endpoint: "users",
    initialQuery: {
      filterUserType,
    },
  });

  React.useEffect(() => {
    console.log(userIdsArr?.length);
  }, [userIdsArr]);

  React.useEffect(() => {
    setQuery({
      ...query,
      filterUserType,
    } as any);

    // eslint-disable-next-line
  }, [props.userType]);

  React.useEffect(() => {
    const idsArr = data.map((item: UserProperties, index: number) => {
      return item.userId;
    });
    const idsStr = idsArr.join(",");
    setUserIds(idsStr);
  }, [data]);

  const getTransactionsByUserIds = async () => {
    const raw = await httpRequest.get<BaseResponseProps<any>>(
      `/transactions/report/total-transaction-by-customers?userIds=${userIds}`
    );
    const arr = raw.data?.payload?.results;
    setUserIdsArr(arr);
  };

  React.useEffect(() => {
    getTransactionsByUserIds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userIds]);

  const handleStatusChange = async () => {
    try {
      setIsLoadingUpdate(true);
      let newData = [];
      newData = data.map((item) => {
        if (item.userId === tmpData.userId) {
          return {
            ...item,
            statusLoading: true,
          };
        }
        return item;
      });

      const newStatus = tmpData.status === "active" ? "inactive" : "active";

      const res = await httpRequest.patch<ResponseProps>(
        "/users/" + tmpData.userId,
        {
          userId: tmpData.userId,
          status: newStatus,
        }
      );

      newData = data.map((item) => {
        if (item.userId === res.data.payload.userId) {
          return {
            ...item,
            status: res.data.payload.status,
            statusLoading: false,
          };
        }
        return item;
      });
      setData(newData);

      message.success("Success change " + tmpData.name + " status.");
      setIsModalVisible(false);
      setTmpData(initialUser);
      setIsLoadingUpdate(false);
    } catch (error: any) {
      message.error(error.data.message);
      setIsModalVisible(false);
      setTmpData(initialUser);
      setIsLoadingUpdate(false);
    }
  };

  const handleClickDetail = (e: UserProperties) => {
    navigate("/" + props.userType + "/" + e.userId);
  };

  // const getNumber;

  const { Option } = Select;

  // const handleChangeStatus = (status: string) => {
  //   console.log(data);
  //   if (status !== "all") {
  //     setQuery((oldVal) => ({ ...oldVal, filterStatus: status }));
  //   } else {
  //     setQuery((oldVal) => ({ ...oldVal, filterStatus: "" }));
  //   }
  // };

  const handleChangeRoleFilter = (role: string) => {
    setQuery((oldVal) => ({ ...oldVal, filterUserType: role }));
  };

  const handleCreateUser = () => {
    navigate({ pathname: "/" + props.userType + "/add" });
  };

  const getQtyPropsByUserId = (userId: string) => {
    const qtyRaw = userIdsArr?.filter((data: any) => data.buyerId === userId);
    return qtyRaw[0];
  };

  const columns = [
    {
      title: "USER ID",
      dataIndex: "userId",
      key: "userId",
      sorter: (a: any, b: any) => {
        return `${a.userId}`.localeCompare(`${b.userId}`);
      },
      render: (userId: string) => {
        return (
          <div>
            <Text>{userId}</Text>
          </div>
        );
      },
    },
    {
      title: "NAMA",
      dataIndex: "name",
      key: "name",
      sorter: (a: any, b: any) => {
        return `${a.name}`.localeCompare(`${b.name}`);
      },
      render: (text: string, record: UserProperties) => {
        return (
          <div className="table-link" onClick={() => handleClickDetail(record)}>
            {text}
          </div>
        );
      },
    },
    {
      title: "EMAIL",
      dataIndex: "email",
      key: "email",
      sorter: (a: any, b: any) => {
        return `${a.email}`.localeCompare(`${b.email}`);
      },
      render: (email: string) => <Text>{email}</Text>,
    },
    {
      title: "NO. TELEPON",
      dataIndex: "phone",
      key: "phone",
      sorter: (a: any, b: any) => {
        return `${a.phone}`.localeCompare(`${b.phone}`);
      },
      render: (phone: string, record: UserProperties) => (
        <Text>
          {record.phone ? (
            record.phone.charAt(0) === "+" ? (
              <Text>{record.phone}</Text>
            ) : (
              <Text>+{record.phone}</Text>
            )
          ) : (
            <Text>-</Text>
          )}
        </Text>
      ),
    },
    {
      title: "STATUS",
      key: "status",
      dataIndex: "status",
      render: (status: any, record: UserProperties) => (
        <>
          <Switch
            checked={status === "active"}
            disabled={ADMIN_EMAIL.includes(record.email) || record.email == user.email}
            onChange={() => {
              setIsModalVisible(true);
              setTmpData(record);
            }}
          />
        </>
      ),
    },
    {
      title: "DIBUAT PADA",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: (a: any, b: any) => {
        const dateA = new Date(a.createdAt).getTime();
        const dateB = new Date(b.createdAt).getTime();
        return dateA - dateB;
      },
      render: (createdAt: any) => <div>{formatDate(createdAt)}</div>,
    },
    {
      title: "DIUBAH PADA",
      dataIndex: "updatedAt",
      key: "updatedAt",
      sorter: (a: any, b: any) => {
        const dateA = new Date(a.updatedAt).getTime();
        const dateB = new Date(b.updatedAt).getTime();
        return dateA - dateB;
      },
      render: (updatedAt: any) => <div>{formatDate(updatedAt)}</div>,
    },
    {
      title: "",
      key: "action",
      render: (_: any, record: UserProperties) => (
        <Dropdown overlay={() => menu(record)} placement="bottomRight">
          <MoreOutlined style={{ cursor: "pointer" }} />
        </Dropdown>
      ),
    },
  ].filter(Boolean) as TableProps<UserProperties>["columns"];

  const handleClick = (key: string, record: UserProperties) => {
    if (key === "edit") {
      navigate({
        pathname: "/" + props.userType + "/" + record.userId + "/edit",
      });
    } else if (key === "change-password") {
      navigate({
        pathname:
          "/" + props.userType + "/" + record.userId + "/change-password",
      });
    } else if (key === "send-email-verification-link") {
      //
      sendEmailVerificationLink(record.email);
    } else if (key === "send-email-forgot-password") {
      //
      sendEmailForgotPasswordLink(record.email);
    }
  };

  const sendEmailVerificationLink = (email: string) => {
    setIsLoadingUpdate(true);
    httpRequest
      .post("/auth/send-email-verification", {
        platform: "web",
        email,
      })
      .then((res) => {
        setIsLoadingUpdate(false);
        message.success("Email verification link has been sent successfully");
      })
      .catch((err) => {
        message.success(
          "Failed to send email verification link. " + getErrorMessage(err)
        );
        setIsLoadingUpdate(false);
      });
  };

  const sendEmailForgotPasswordLink = (email: string) => {
    setIsLoadingUpdate(true);
    httpRequest
      .post("/auth/forgot-password/confirmation-link", {
        platform: "web",
        email,
      })
      .then((res) => {
        setIsLoadingUpdate(false);
        message.success("Link reset password has been sent successfully");
      })
      .catch((err) => {
        message.success(
          "Failed to send link reset password. " + getErrorMessage(err)
        );
        setIsLoadingUpdate(false);
      });
  };

  const ADMIN_EMAIL = [
    "qbit.acai@gmail.com",
    'gudangacai@qbit.co.id'
  ]

  const menu = (record: UserProperties) => (
    <Menu onClick={(e) => handleClick(e.key, record)}>
      
      {!(ADMIN_EMAIL.includes(record.email)) ? (
        <>
          <Menu.Item key="change-password">Change Password</Menu.Item>
          <Menu.Item key="edit">
            Edit {props.userType === "admin" ? "Admin" : "Customer"}
          </Menu.Item>
        </>
      ) : (
        false
      )}
    </Menu>
  );

  return (
    <React.Fragment>
      <HeaderSection
        icon={<UserOutlined />}
        title={props.userType === "admin" ? "Admin" : "Customer"}
        subtitle={
          props.userType === "admin"
            ? "Manage your admin"
            : "Manage your customer"
        }
        rightAction={[
          <Button
            // icon={<PlusOutlined />}
            type="primary"
            onClick={handleCreateUser}
          >
            {props.userType === "admin" ? "Add Admin" : "Add Customer"}
          </Button>,
        ]}
      />
      <div style={{ padding: "0px 20px" }}>
        <ContainerFilter>
          <Input
            style={{ width: "100%" }}
            // className="outer-shadow"
            placeholder="Search by Name, Phone number or Email"
            suffix={<SearchOutlined />}
            allowClear
            onChange={(e) => setSearch(e.target.value)}
          />
          {props.userType === "admin" ? (
            <Select
              style={{ width: "25%" }}
              // className="outer-shadow"
              onChange={handleChangeRoleFilter}
              placeholder="Role"
            >
              <Option
                value={Object.keys(RoleList)
                  .filter((item) => item !== "customer")
                  .join(",")}
              >
                All
              </Option>
              {Object.keys(RoleList)
                .filter((item) => item !== "customer")
                .map((key) => (
                  <Option key={key} value={key}>
                    {RoleList[key]}
                  </Option>
                ))}
            </Select>
          ) : (
            <React.Fragment></React.Fragment>
          )}
          {/* <Select
            // className="outer-shadow"
            style={{ width: "15%" }}
            onChange={handleChangeStatus}
            placeholder="Status"
          >
            <Option value="all">All</Option>
            <Option value="1">Active</Option>
            <Option value="0">Non Active</Option>
          </Select> */}
        </ContainerFilter>
        {userIdsArr ? (
          <Table
            rowKey="userId"
            loading={isLoading}
            columns={columns}
            dataSource={data}
            pagination={{
              current: pagination.page,
              total: pagination.totalData,
              defaultPageSize: pagination.perPage,
              pageSize: pagination.perPage,
              onChange: changePage,
            }}
          />
        ) : (
          <></>
        )}
      </div>

      <Modal
        title="Update status confirmation"
        open={isModalVisible}
        onOk={handleStatusChange}
        onCancel={() => {
          setIsModalVisible(false);
          setTmpData(initialUser);
        }}
        okText="Yes"
        confirmLoading={isLoadingUpdate}
        okButtonProps={{ type: "primary" }}
      >
        <p>
          Are you sure want to change <b>"{tmpData.name}"</b> status to{" "}
          <b>"{tmpData.status === "active" ? "Inactive" : "Active"}"</b>?.{" "}
          {tmpData.status === "active"
            ? 'Jika user ini statusnya diubah menjadi "Inactive", maka user ini tidak akan bisa login ke Gudang Acai'
            : 'Jika user ini statusnya diubah menjadi "Active", maka user ini bisa Sign In ke Gudang Acai'}
        </p>
      </Modal>
    </React.Fragment>
  );
};
export default User;

export const ContainerFilter = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 15px;
`;
