import React from "react";
import styled from "styled-components";

interface InfoProps {
  title?: string;
  content: string;
}

interface SectionContentProps {
  groupTitle: string;
  groupSubtitle?: string;

  helpers?: InfoProps[];
  children?: any;
}

const CustomFormGroup = ({ column, children }: any) => {
  if (column === 2) {
    return <FormGroupTwoColumn>{children}</FormGroupTwoColumn>;
  } else {
    return <FormGroup>{children}</FormGroup>;
  }
};

const SectionContent: React.FC<SectionContentProps> = ({
  groupTitle,
  groupSubtitle,
  helpers,
  children,
}) => {
  return (
    <CustomFormGroup column={helpers ? 3 : 2}>
      <div style={{ paddingTop: 10 }}>
        <FormGroupTitle>{groupTitle}</FormGroupTitle>
        <FormGroupSubtitle>{groupSubtitle}</FormGroupSubtitle>
      </div>
      <FormContent>{children}</FormContent>
      {helpers ? (
        <FormGroupHelper>
          {helpers &&
            helpers?.map((item, idx) => {
              return (
                <div key={idx}>
                  <div id="title">{item.title}</div>
                  <div id="subtitle">{item.content}</div>
                </div>
              );
            })}
        </FormGroupHelper>
      ) : (
        false
      )}
    </CustomFormGroup>
  );
};

export const FormGroup = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr 300px;
  gap: 20px;
  align-items: flex-start;
  margin-bottom: 30px;
`;

export const FormGroupTwoColumn = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr;
  gap: 20px;
  align-items: flex-start;
  margin-bottom: 30px;
`;

export const FormGroupTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSize.body1};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-bottom: 10px;
`;

export const FormGroupSubtitle = styled.div`
  font-size: ${({ theme }) => theme.fontSize.smallText};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

export const FormContent = styled.div`
  width: 100%;
  padding-top: 10px;
`;

export const FormGroupHelper = styled.div`
  padding-top: 10px;
  margin-bottom: 15px;

  #title {
    font-size: ${({ theme }) => theme.fontSize.body};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  }
  #subtitle {
    font-size: ${({ theme }) => theme.fontSize.smallText};
    color: ${({ theme }) => theme.colors.charcoal200};
  }
`;

export default SectionContent;
