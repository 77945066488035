import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import moment from "moment";
import { TransactionProps } from "../../types/transaction";
import { MoistureContentProps } from "../../types/moistureContent.type";

// Font.register({
//   family: "Poppins",
//   fonts: [
//     {
//       src: PoppinsRegular,
//       fontWeight: 400,
//     },
//     {
//       src: "/src/assets/fonts/Poppins-Medium.ttf",
//       fontWeight: 500,
//     },
//     {
//       src: "/src/assets/fonts/Poppins-Bold.ttf",
//       fontWeight: 700,
//     }
//   ],
// });

// Create styles
const styles = StyleSheet.create({
  page: {
    padding: "3.5vw 6vw 3.5vw 6vw",
    fontFamily: "Courier",
  },
});

const stylesB = StyleSheet.create({
  page: {
    padding: "3.5vw 10vw 3.5vw 2vw",
    fontFamily: "Courier-Bold",
  },
});

interface pdfProps {
  // transactionId?: string;
  transactions: TransactionProps[];
}

interface parsedMoistureContent {
  id: string;
  transactionId: string;
  moistureContentId: string;
  metaMoistureContent: MoistureContentProps;
  seedWeight: number;
  createdAt?: Date;
  updatedAt?: Date;
}

// Create Document Component
const MyDocument = (props: pdfProps) => {
  const { transactions } = props;

  const date = moment().format("DD MMMM YYYY, HH:mm:ss");

  return (
    <Document>
      {transactions.map((transaction) => {
        const transactionCreated = moment(transaction?.createdAt || 0).format(
          "DD MMMM YYYY, HH:mm:ss"
        );
        const moistureContentData = transaction.TransactionMoistureContents;

        return (
          <Page
            size={[306, 396]}
            orientation="portrait"
            style={stylesB.page}
            wrap
            key={transaction.transactionId}
          >
            <View
              style={{ width: "20%", paddingRight: "1.67vw", margin: "0..2vw", height: "10vh" , marginLeft:"auto", marginRight: "auto" }}
            >
              <Image
                src="/images/logo_small.png"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            </View>
            <View style={{ flexDirection: "row", maxHeight:"12%" }}>
              <View style={{ width: "100%"}} wrap>
                {/* <Text
                  style={{ fontSize: "2.14vw", fontWeight: 800, marginBottom: "1.67vw" }}
                > */}
                <Text
                  style={{ fontSize: "3.8vw", fontWeight: 900, textAlign:"center" }}
                >
                  STRUK/NOTA PEMBAYARAN PENGOLAHAN JAGUNG
                </Text>
                {/* <Text style={{ fontSize: "1.67vw", paddingRight: "4.76vw" }}>*/}
                <Text style={{ fontSize: "3vw", textAlign:"center" }}>
                  Jl. Lintas Calabai, Desa Doromelo, Kec. Manggelewa Kab.
                  Dompu, Nusa Tenggara Barat, 84253
                </Text>
              </View>
              {/* <View style={{ width: "20%", paddingTop: "1.67vw", paddingLeft:"2vw" }} wrap>
                <Text style={{ fontSize: "2.4vw" }}>
                  DICETAK TANGGAL
                </Text>
                 <Text style={{ fontSize: "3.5vw", fontWeight: 800 }}>{date}</Text>
              </View> */}
            </View>
            <View>
              <Text style={{ fontSize: "2.14vw", marginTop: "1.2vw" }}>
                ---------------------------------------------------------------------
              </Text>
              <View
                style={{
                  // flexDirection: "row",
                  // justifyContent: "space-around",
                  height: "auto",
                }}
              >
                {/* <View style={{ width: "60%", }}> */}
                  <View style={{ flexDirection: "row" }}>
                    <Text style={{ fontSize: "3.5vw", width: "35%" }}>
                      Kode Transaksi
                    </Text>
                    <Text style={{ fontSize: "3.5vw" }}>: </Text>
                    <Text style={{ fontSize: "3.5vw" }}>
                      {transaction.transactionCode}
                    </Text>
                  </View>
                  <View style={{ flexDirection: "row" }}>
                    <Text style={{ fontSize: "3.5vw", width: "35%" }}>
                      Tanggal Dibuat
                    </Text>
                    <Text style={{ fontSize: "3.5vw" }}>: </Text>
                    <Text style={{ fontSize: "3.5vw" }}>
                      {transactionCreated.toString()}
                    </Text>
                  </View>
                {/* </View> */}
                {/* <View style={{ width: "40%" }}> */}
                  <View style={{ flexDirection: "row"}}>
                    <Text style={{ fontSize: "3.5vw", width: "35%" }}>Nama </Text>
                    <Text style={{ fontSize: "3.5vw", width: "4%" }}>: </Text>
                    <Text style={{ fontSize: "3.5vw", width: "61%" }}>
                      {transaction.senderName}
                    </Text>
                  </View>
                  <View style={{ flexDirection: "row" }}>
                    <Text style={{ fontSize: "3.5vw", width: "35%" }}>Mobil</Text>
                    <Text style={{ fontSize: "3.5vw" }}>: </Text>
                    <Text style={{ fontSize: "3.5vw" }}>
                      {transaction.senderCarType +
                        "-" +
                        transaction.senderPlateNumber}
                    </Text>
                  </View>
                {/* </View> */}
              </View>
              <Text style={{ fontSize: "2.14vw" }}>
                ---------------------------------------------------------------------
              </Text>
            </View>
            <View style={{ display: "flex" }}>
              <View style={{ justifyContent: "space-around", gap: "0..2vw" }}>
                {/* <View style={{ flexDirection: "row" }}>
                  <Text style={{ fontSize: "3.5vw", width: "45%" }}>Berat Kotor</Text>
                  <Text style={{ fontSize: "3.5vw" }}>: </Text>
                  <Text style={{ fontSize: "3.5vw", marginLeft: "auto" }}>
                    {transaction.grossWeight
                      .toLocaleString("id-ID")
                      .replace(/,/g, ".")}{" "}
                    Kg
                  </Text>
                </View> */}
                {/* <View style={{ flexDirection: "row" }}>
                  <Text style={{ fontSize: "3.5vw", width: "45%" }}>
                    Berat Kosong
                  </Text>
                  <Text style={{ fontSize: "3.5vw" }}>: </Text>
                  <Text style={{ fontSize: "3.5vw", marginLeft: "auto" }}>
                    (
                    {transaction.emptyWeight
                      .toLocaleString("id-ID")
                      .replace(/,/g, ".")}
                    ) Kg
                  </Text>
                </View> */}
                <View style={{ flexDirection: "row" }}>
                  <Text style={{ fontSize: "3.5vw", width: "45%" }}>
                    Total Berat
                  </Text>
                  <Text style={{ fontSize: "3.5vw" }}>: </Text>
                  <Text style={{ fontSize: "3.5vw", marginLeft: "auto" }}>
                    {(transaction.grossWeight - transaction.emptyWeight)
                      .toLocaleString("id-ID")
                      .replace(/,/g, ".")}{" "}
                    Kg
                  </Text>
                </View>
              </View>
              {/* <Text style={{ fontSize: "2.14vw" }}>
                --------------------------------------------------------------------------------------------------
              </Text> */}
              
              <Text style={{ fontSize: "2.14vw" }}>
                ---------------------------------------------------------------------
              </Text>
            </View>
            <View style={{ display: "flex" }}>
              <View style={{ flexDirection: "row", marginTop: "0.6vh" }}>
                <Text style={{ fontSize: "3.5vw", width: "40%" }}>
                  Kadar Air
                  </Text>
                <Text style={{ fontSize: "3.5vw", width: "30%"}}>
                  Potongan
                  </Text>
                <Text style={{ fontSize: "3.5vw", marginLeft: "auto" }}>
                  Berat Bersih
                </Text>
              </View>
              <Text style={{ fontSize: "2.14vw" }}>
                ---------------------------------------------------------------------
              </Text>
              <View>
                {moistureContentData
                  .sort((a, b) => {
                    return (
                      a.metaMoistureContent.minimumLimit -
                      b.metaMoistureContent.minimumLimit
                    );
                  })
                  .map((detail: parsedMoistureContent, index) => {
                    if (detail.seedWeight !== 0) {
                      return (
                        <View style={{ flexDirection: "row" }} key={index}>
                          <View
                            style={{
                              width: "40%",
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-start",
                            }}
                          >
                            <Text style={{ fontSize: "3.5vw" }}>
                              {detail.metaMoistureContent.minimumLimit} -{" "}
                              {detail.metaMoistureContent.maximumLimit}
                            </Text>
                            <Text style={{ fontSize: "3.5vw" }}>&nbsp;%</Text>
                          </View>
                          <View
                            style={{
                              width: "30%",
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-start",
                            }}
                          >
                            <Text style={{ fontSize: "3.5vw" }}>
                              {detail.metaMoistureContent.percentage}
                            </Text>
                            <Text style={{ fontSize: "3.5vw" }}>&nbsp;%</Text>
                          </View>
                          <Text style={{ fontSize: "3.5vw", marginLeft: "auto" }}>
                            {Math.floor(
                              (detail.seedWeight *
                                (100 - detail.metaMoistureContent.percentage)) /
                                100
                            )
                              .toLocaleString("id-ID")
                              .replace(/,/g, ".")}
                            &nbsp;Kg
                          </Text>
                        </View>
                      );
                    } else {
                      return null;
                    }
                  })}
              </View>
            </View>
            <Text style={{ fontSize: "2.14vw" }}>
              ---------------------------------------------------------------------
            </Text>
            <View style={{ flexDirection: "row" }}>
              <Text style={{ fontSize: "3.5vw", width: "45%" }}>
                Total Berat Bersih
              </Text>
              <Text style={{ fontSize: "3.5vw" }}>
                :{" "}
              </Text>
              <View
                style={{
                  fontSize: "3.5vw",
                  marginLeft: "auto",
                  width: "35%",
                  flexDirection: "row",
                }}
              >
                <View style={{ marginLeft: "auto", flexDirection: "row" }}>
                  <Text style={{ fontSize: "3.5vw" }}>
                    {(transaction.netWeight)
                      .toLocaleString("id-ID")
                      .replace(/,/g, ".")}
                  </Text>
                  <Text style={{ fontSize: "3.5vw" }}> Kg</Text>
                </View>
              </View>
            </View>
            <View style={{ flexDirection: "row" }}>
              <Text style={{ fontSize: "3.5vw", width: "45%", fontWeight: 700 }}>
                Harga Bersih per Kg
              </Text>
              <Text style={{ fontSize: "3.5vw" }}>: </Text>
              <View
                  style={{
                    fontSize: "3.5vw",
                    marginLeft: "auto",
                    width: "35%",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Text style={{ fontSize: "3.5vw" }}>Rp</Text>
                  <Text style={{ fontSize: "3.5vw" }}>
                    {transaction.netCornPrice
                      .toLocaleString("id-ID")
                      .replace(/,/g, ".")} /Kg
                  </Text>
                </View>
            </View>
            <View style={{ display: "flex" }}>
              <View style={{ justifyContent: "space-around" }}>
                <View style={{ flexDirection: "row" }}>
                  <Text style={{ fontSize: "3.5vw", width: "45%" }}>
                    Harga Bersih
                  </Text>
                  <Text style={{ fontSize: "3.5vw" }}>
                    :{" "}
                    {/* {transaction.netWeight
                      .toLocaleString("id-ID")
                      .replace(/,/g, ".")}{" "}
                    Kg x Rp
                    {transaction.netCornPrice
                      .toLocaleString("id-ID")
                      .replace(/,/g, ".")} */}
                  </Text>
                  <View
                    style={{
                      fontSize: "3.5vw",
                      marginLeft: "auto",
                      width: "35%",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Text style={{ fontSize: "3.5vw" }}>Rp</Text>
                    <Text style={{ fontSize: "3.5vw" }}>
                      {(transaction.netWeight * transaction.netCornPrice)
                        .toLocaleString("id-ID")
                        .replace(/,/g, ".")}
                    </Text>
                  </View>
                </View>
                <View style={{ flexDirection: "row" }}>
                  <Text style={{ fontSize: "3.5vw", width: "45%" }}>Bri Link</Text>
                  <Text style={{ fontSize: "3.5vw" }}>: </Text>
                  <View
                    style={{
                      fontSize: "3.5vw",
                      marginLeft: "auto",
                      width: "35%",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Text style={{ fontSize: "3.5vw" }}>Rp</Text>
                    <Text style={{ fontSize: "3.5vw" }}>
                      (
                      {(transaction.briLink || 0)
                        .toLocaleString("id-ID")
                        .replace(/,/g, ".")}
                      )
                    </Text>
                  </View>
                </View>
              </View>
              <Text style={{ fontSize: "2.14vw" }}>
                =====================================================================
              </Text>
            </View>
            <View>
              <View style={{ flexDirection: "row" }}>
                <Text
                  style={{
                    fontSize: "3.5vw",
                    width: "30%",
                    fontWeight: "ultrabold",
                  }}
                >
                  TOTAL
                </Text>
                <View
                    style={{
                      fontSize: "3.5vw",
                      marginLeft: "auto",
                      width: "35%",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Text style={{ fontSize: "3.5vw" }}>Rp</Text>
                    <Text style={{ fontSize: "3.5vw" }}>
                      {transaction.totalPrice
                        .toLocaleString("id-ID")
                        .replace(/,/g, ".")}
                    </Text>
                  </View>
              </View>
            </View>
            <Text style={{ fontSize: "2.14vw" }}>
              =====================================================================
            </Text>
            <View style={{ display: "flex" }}>
              <View style={{ flexDirection: "row", marginTop: "0.6vw" }}>
                <Text
                  style={{
                    fontSize: "3.5vw",
                    width: "25%",
                    fontWeight: "ultrabold",
                  }}
                >
                  Catatan :
                </Text>
              </View>
            </View>
            {/* <View style={{ flexDirection: "row", marginTop: "auto"}}>
              <Text
                style={{
                fontSize: "2.4vw",
                fontWeight: "ultrabold",
                marginLeft: "auto" 
              }}              
            >
              Dicetak: {date}
            </Text>
          </View> */}
          </Page>
        );
      })}
    </Document>
  );
};

export default MyDocument;
