import { UserOutlined } from "@ant-design/icons";
import HeaderSection from "../../components/HeaderSection";
import { useNavigate } from "react-router-dom";
import { Space, Image, Button, Card, Row, Col } from "antd";
import DetailItem from "../../components/DetailItem";
import useAuth from "../../hooks/useAuth";
import { showRoleName } from "../../helpers/auth";
import { capitalizeFirstLetter } from "../../helpers/text";
import React from "react";
import SectionContent from "../../components/SectionContent";

const Profile = () => {
  const navigate = useNavigate();

  const { user } = useAuth();

  const handleClickEdit = () => {
    navigate("/profile/edit");
  };

  const handleClickChangePassword = () => {
    navigate("/change-password");
  };

  return (
    <React.Fragment>
      <HeaderSection
        icon={<UserOutlined />}
        title="Profile"
        subtitle="Manage your profile"
        rightAction={
          <Space>
            <Button type="primary" onClick={handleClickChangePassword}>
              Change Password
            </Button>
            <Button type="primary" onClick={handleClickEdit}>
              Edit Profile
            </Button>
          </Space>
        }
      />
      <Card bordered={false} style={{ boxShadow: "none" }}>
        <SectionContent
          groupTitle="IDENTITAS USER"
          groupSubtitle="Berikut adalah identitas User"
        >
          <Row gutter={[24, 0]}>
            <Col span={12}>
              <DetailItem label="Name" value={user.name} />
              <DetailItem label="Email" value={user.email} />
              <DetailItem label="Phone Number" value={user.phone} />
            </Col>
            <Col span={12}>
              <DetailItem
                label="Status"
                value={capitalizeFirstLetter(user.status!)}
              />
              <DetailItem label="Role" value={showRoleName(user.userType!)} />
            </Col>
          </Row>
        </SectionContent>
      </Card>
    </React.Fragment>
  );
};

export default Profile;
