import Chart from 'react-apexcharts';
import { TransactionProps } from '../../types/transaction';
import moment from 'moment';
import { formatPrice } from '../../helpers/priceFormat';

export default function ChartDashboard({
  transactions,
  filterType,
  resFilterDate,
}: {
  transactions: TransactionProps[];
  filterType?: any;
  resFilterDate?: any;
}) {
  let fType = filterType || 'year';

  const totalDay = moment(resFilterDate?.endAt).format('DD');
  const day = Array.from({ length: +totalDay }, (_, i) => i + 1);

  let month = [
    'Januari',
    'Febuari',
    'Maret',
    'April',
    'Mei',
    'Juni',
    'Juli',
    'Agustus',
    'September',
    'Oktober',
    'November',
    'Desember',
  ];

  let yearResults = Array.from({ length: 12 }, (_, i) => i).map((x) => {
    const trans = transactions.filter(
      (t) => x === ((+moment(t.createdAt).format('M') - 1) as any)
    );

    if (trans) {
      return {
        month: month[x],
        total: trans.reduce((acc, curr) => acc + curr.totalPrice, 0),
      };
    } else {
      return {
        month: month[x],
        total: 0,
      };
    }
  });

  let monthResults = day.map((x) => {
    const trans = transactions.filter(
      (t) => x === (+moment(t.createdAt).format('DD') as any)
    );

    if (trans) {
      return {
        date: x,
        total: trans.reduce((acc, curr) => acc + curr.totalPrice, 0),
      };
    } else {
      return {
        date: x,
        total: 0,
      };
    }
  });

  const start = moment(resFilterDate?.startAt);
  const end = moment(resFilterDate?.endAt);

  const dates: string[] = [];
  let current = start.clone();

  while (current.isSameOrBefore(end, 'day')) {
    dates.push(current.format());
    current.add(1, 'day');
  }

  let timeSpanResults = dates.map((date) => {
    const trans = transactions.filter(
      (t) =>
        moment(date).format('YYYY-MM-DD') ===
        (moment(t.createdAt).format('YYYY-MM-DD') as any)
    );

    if (trans) {
      return {
        date: date,
        total: trans.reduce((acc, curr) => acc + curr.totalPrice, 0),
      };
    } else {
      return {
        date: date,
        total: 0,
      };
    }
  });

  let categories =
    fType === 'year'
      ? yearResults.map((x) => x.month)
      : fType === 'month'
      ? day
      : dates.map((date) => +moment(date).format('DD'));

  function formatNumber(number: number) {
    if (number >= 1000000) {
      return (number / 1000000).toFixed(0) + 'jt';
    } else {
      return (number / 1000).toFixed(0) + 'k';
    }
  }

  let chartState: any = {
    options: {
      theme: {
        mode: 'light',
        palette: 'palette10',
        monochrome: {
          enabled: true,
          color: '#FFB400',
          shadeTo: 'light',
          shadeIntensity: 0.65,
        },
      },
      colors: ['#FFB400'],
      stroke: {
        width: 3,
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'light',
          type: 'vertical',
          shadeIntensity: 0.5,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          colorStops: [
            {
              offset: 0,
              color: '#ffc107',
              opacity: 1,
            },
            {
              offset: 100,
              color: '#ff9800',
              opacity: 1,
            },
          ],
        },
      },
      chart: {
        id: 'example-chart',
        toolbar: {
          show: false,
        },
      },
      markers: {
        size: 6,
        hover: {
          size: 8, // set marker size to 10 when hovered
        },
      },
      xaxis: {
        categories,
      },
      yaxis: {
        title: {
          text: 'Total Transaksi Rupiah',
          style: {
            fontSize: 12,
            color: '#1e1e1e',
            fontFamily: 'Poppins',
            fontWeight: '500',
          },
        },
        labels: {
          formatter: function (value: number) {
            return formatNumber(value);
          },
        },
      },
      tooltip: {
        enabled: true,
        custom: function ({ series, seriesIndex, dataPointIndex, w }: any) {
          console.log(w.globals.labels[dataPointIndex]);
          return (
            '<div style="padding: 8px 12px; border: 1px solid #FFB400; border-radius: 3px;">' +
            '<div style="font-size: 12px; color: #1e1e1e; margin-bottom: 0px;">' +
            `${
              fType === 'year'
                ? `${month[dataPointIndex]} ${moment(
                    resFilterDate?.endAt
                  ).format('YYYY')}`
                : fType === 'month'
                ? `${w.globals.labels[dataPointIndex]} ${moment(
                    resFilterDate?.endAt
                  ).format('MMM YYYY')}`
                : `${moment(dates[dataPointIndex]).format('DD MMM YYYY')}`
            }` +
            '</div>' +
            `<div class="flex items-center gap-x-1">
            <div style="width: 5px; height: 5px; border-radius: 50%; background-color: #FFB400; margin-top: -2px"></div>
            <div style="font-size: 10px;">Pengeluaran</div>
            <div style="font-size: 12px; font-weight: 600;">Rp${formatPrice(
              series[seriesIndex][dataPointIndex]
            )}</div>
              </div>` +
            '</div>'
          );
        },
      },
    },
    series: [
      {
        name: '',
        data:
          fType === 'year'
            ? yearResults.map((y) => y.total)
            : fType === 'month'
            ? monthResults.map((y) => y.total)
            : timeSpanResults.map((y) => y.total),
      },
    ],
  };

  return (
    <Chart
      options={chartState.options}
      series={chartState.series}
      type="line"
      width="100%"
      height="300"
    />
  );
}
