import { Typography } from 'antd';
import styled from 'styled-components';
import { theme } from '../../assets/theme';
import { formatPrice } from '../../helpers/priceFormat';
import { formatKg } from '../../helpers/constant';
const { Text } = Typography;

const TransactionItemSummary = (props: {
  title: string;
  icon: JSX.Element;
  sup?: string;
  total: number;
}) => {
  return (
    <TransactionInfoItemWrapper>
      <div className="top">
        {props.icon && <div className="icon">{props.icon}</div>}

        <Text className="title">{props.title}</Text>
      </div>
      <div className="bottom">
        {props.sup === 'Rp' ? (
          <>
            <span className="sup">{props.sup}</span>{' '}
            <Text className="count">{formatPrice(props.total || 0)}</Text>
          </>
        ) : props.sup === 'Kg' ? (
          <>
            <Text className="count">{formatKg(props.total || 0)}</Text>
            <span className="sup">{props.sup}</span>
          </>
        ) : (
          <>
            <Text className="count">{props.total}</Text>
          </>
        )}
      </div>
    </TransactionInfoItemWrapper>
  );
};

export default TransactionItemSummary;

const TransactionInfoItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 20px;
  border: 1px solid #e8ecf3;
  gap: 8px;

  .top {
    display: flex;
    align-items: center;
    gap: 14px;

    .icon {
      height: 32px;
      width: 32px;
      background-color: #e8ecf3;
      display: grid;
      place-items: center;
      border-radius: 8px;
    }

    .title {
      font-size: 16px;
      font-weight: 600;
      color: #3f4c5f;
    }
  }

  .bottom {
    display: flex;
    align-items: flex-start;
    gap: 6px;
    .count {
      font-size: 31px;
      font-weight: 600;
      color: ${theme.colors.primary_green};
    }

    span.sup {
      font-size: 14px;
      font-weight: 600;
      color: #9facbf;
      padding-top: 5px;
    }
  }
`;
