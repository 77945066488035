import { InfoCircleOutlined } from "@ant-design/icons";
import {
  Typography,
  Button,
  Space,
  Row,
  Col,
  Divider,
  List,
  Card,
  Checkbox,
  InputNumber,
  Modal,
  Input,
  Form,
  Alert,
} from "antd";
import Link from "antd/lib/typography/Link";
import { arrowIcon as IconArrow } from "../../assets/icons";
import HeaderSection from "../../components/HeaderSection";
import useFetchList from "../../hooks/useFetchList";
import React, { useEffect } from "react";
import {
  TransactionProps,
  initialTransactions,
  transactionMoistureContentItemProperties,
} from "../../types/transaction";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { httpRequest } from "../../helpers/api";
import Section from "../../components/Section";
import { MoistureContentProps } from "../../types/moistureContent.type";
import Meta from "antd/lib/card/Meta";
import { priceFormat } from "../../helpers/priceFormat";
import _ from "lodash";

export function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

interface ILocation {
  transactionId: string;
}

interface ModalFormProps {
  open: boolean;
  onCreate: (values: string) => void;
  onCancel: () => void;
  title: string;
  defaultValue: string;
}

const { Text } = Typography;

const EditTransaction = () => {
  const navigate = useNavigate();
  const { transactionId } = useParams<keyof ILocation>() as ILocation;
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [transaction, setTransaction] = React.useState<TransactionProps>({
    ...initialTransactions,
  });
  const [isDraft, setIsDraft] = React.useState<boolean>(true);
  const [moistureContent, setMoistureContent] = React.useState<
    MoistureContentProps[]
  >([]);
  const oldMoistureContent = transaction.TransactionMoistureContents.map(
    (item: transactionMoistureContentItemProperties) => {
      return {
        ...item.metaMoistureContent,
      };
    }
  );
  const [totalGrossWeight, setTotalGrossWeight] = React.useState<number>(0);
  const [moistureContentTotalWeight, setMoistureContentTotalWeight] =
    React.useState<number>(0);
  const [isDeadSeedActive, setIsDeadSeedActive] =
    React.useState<boolean>(false);
  const [isLimeSeedActive, setIsLimeSeedActive] =
    React.useState<boolean>(false);
  const [isModalConfirmationShow, setIsModalConfirmationShow] =
    React.useState<boolean>(false);
  const [isModalDeadSeedShow, setIsModalDeadSeedShow] =
    React.useState<boolean>(false);
  const [isModalLimeSeedShow, setIsModalLimeSeedShow] =
    React.useState<boolean>(false);

  const transactionCreated = moment(transaction?.createdAt || 0);
  const transactionUpdated = moment(transaction?.updatedAt || 0);

  const {
    // isLoading,
    data,
    // setIsLoading,
  } = useFetchList<MoistureContentProps>({
    endpoint: "moistureContent",
    initialQuery: {
      isActive: true,
    },
  });

  useEffect(() => {
    const fetchTransaction = async () => {
      try {
        setIsLoading(true);
        const res = await httpRequest.get("/transactions/" + transactionId);
        const dataTransaction = res.data.payload;

        setTransaction(dataTransaction);
        if (!dataTransaction.isDraft) {
          navigate("/transactions/" + transactionId);
        }
        const oldMoistureContent =
          dataTransaction.TransactionMoistureContents.map(
            (item: transactionMoistureContentItemProperties) => {
              return {
                ...item.metaMoistureContent,
              };
            }
          );

        // console.log("oldMoistureContent", oldMoistureContent);
        // console.log("data", data);
        // console.log(_.isEqual(oldMoistureContent, data));
        setIsDeadSeedActive(Boolean(dataTransaction.deadSeedPrice));
        setIsLimeSeedActive(Boolean(dataTransaction.limeSeedPrice));
        setMoistureContent(oldMoistureContent);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };
    fetchTransaction();
  }, [transactionId]);

  const setNetWeight = (value: any) => {
    const valueNum = value ?? 0;
    setTransaction({
      ...transaction,
      netWeight: valueNum,
    });
  };

  const setNetPrice = (value: any) => {
    const valueNum = value ? parseInt(value) : 0;
    setTransaction({
      ...transaction,
      netCornPrice: valueNum,
    });
  };

  const setGrossWeight = (value: any) => {
    const valueNum = value ? parseInt(value) : 0;
    setTransaction({
      ...transaction,
      grossWeight: valueNum,
    });
  };

  const setEmptyWeight = (value: any) => {
    const valueNum = value ? parseInt(value) : 0;
    setTransaction({
      ...transaction,
      emptyWeight: valueNum,
    });
  };

  const setMoistureContentWeight = (value: any, moistureContentId: any) => {
    const valueNum = value ? parseInt(value) : 0;
    transaction.TransactionMoistureContents.find(
      (item: transactionMoistureContentItemProperties) => {
        if (item.moistureContentId === moistureContentId) {
          item.seedWeight = valueNum;
        }
      }
    );

    const totalWeight = transaction.TransactionMoistureContents.reduce(
      (acc, item) => {
        const moistureContentData = data.find(
          (dataItem) => dataItem.moistureContentId === item.moistureContentId
        );
        return (
          acc +
          Math.floor(
            (item.seedWeight * (100 - (moistureContentData?.percentage || 0))) /
              100
          )
        );
      },
      0
    );

    let isDraftValue = true;
    if (transaction.grossWeight) {
      isDraftValue = !(
        transaction.grossWeight - transaction.emptyWeight ==
        transaction.TransactionMoistureContents.reduce((a, b) => a + b.seedWeight, 0)
      );
    }
    setIsDraft(isDraftValue);

    setNetWeight(totalWeight);
  };

  // const setDeadSeedPrice = (value: any) => {
  //   const valueNum = value ? parseInt(value) : 0;
  //   setTransaction({
  //     ...transaction,
  //     deadSeedPrice: valueNum,
  //   });
  // };

  // const setDeadSeedStatus = (value: any) => {
  //   setIsDeadSeedActive(value.target.checked);
  //   // reset dead seed price if unchecked
  //   if (!value.target.checked) {
  //     setDeadSeedPrice(0);
  //   }
  // };

  // const setDeadSeedComment = (value: any) => {
  //   setTransaction({
  //     ...transaction,
  //     deadSeedComment: value,
  //   });
  // };

  // const setLimeSeedPrice = (value: any) => {
  //   const valueNum = value ? parseInt(value) : 0;
  //   setTransaction({
  //     ...transaction,
  //     limeSeedPrice: valueNum,
  //   });
  // };

  // const setLimeSeedComment = (value: any) => {
  //   setTransaction({
  //     ...transaction,
  //     limeSeedComment: value,
  //   });
  // };

  // const setLimeSeedStatus = (value: any) => {
  //   setIsLimeSeedActive(value.target.checked);
  //   // reset lime seed price if unchecked
  //   if (!value.target.checked) {
  //     setLimeSeedPrice(0);
  //   }
  // };

  const setBriLink = (value: any) => {
    const valueNum = value ? parseInt(value) : 0;
    setTransaction({
      ...transaction,
      briLink: valueNum,
    });
  };

  useEffect(() => {
    setTotalGrossWeight(transaction.grossWeight - transaction.emptyWeight);
    let isDraftValue = true;
    if (transaction.grossWeight) {
      isDraftValue = !(
        transaction.grossWeight - transaction.emptyWeight ==
        transaction.TransactionMoistureContents.reduce((a, b) => a + b.seedWeight, 0)
      );
    }
    setIsDraft(isDraftValue);
  }, [transaction.emptyWeight, transaction.grossWeight]);

  useEffect(() => {
    setMoistureContentTotalWeight(
      transaction.TransactionMoistureContents.reduce(
        (acc, item) => acc + item.seedWeight,
        0
      )
    );

    console.log(transaction);

    // setTransaction({
    //   ...transaction,
    //   TransactionMoistureContents: transaction.TransactionMoistureContents.map(
    //     (item: transactionMoistureContentItemProperties) => {
    // });
  }, [transaction]);

  useEffect(() => {
    setTransaction({
      ...transaction,
      totalPrice:
        transaction.netWeight * transaction.netCornPrice -
        (transaction.briLink || 0),
    });
  }, [transaction.netCornPrice, transaction.briLink]);

  const showConfirmationModal = () => {
    setIsModalConfirmationShow(true);
  };

  const hideConfirmationModal = () => {
    setIsModalConfirmationShow(false);
  };

  // const showDeadSeedModal = () => {
  //   setIsModalDeadSeedShow(true);
  // };

  // const hideDeadSeedModal = () => {
  //   setIsModalDeadSeedShow(false);
  // };

  // const showLimeSeedModal = () => {
  //   setIsModalLimeSeedShow(true);
  // };

  // const hideLimeSeedModal = () => {
  //   setIsModalLimeSeedShow(false);
  // };

  const sendData = async () => {
    try {
      // const await httpRequest.post("/transactions", transactionProperties);
      const response = await httpRequest.patch(
        "/transactions/" + transaction.transactionId,
        transaction
      );
      console.log(response);
      if (response.status === 200) {
        hideConfirmationModal();
        // reload page
        window.location.reload();
      } else {
        console.log(response);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <React.Fragment>
      <HeaderSection
        icon={"back"}
        title={
          "Edit Transaksi" +
          (transaction.transactionCode
            ? ` #${transaction.transactionCode}`
            : "")
        }
        rightAction={
          <Space>
            <Button style={{ backgroundColor: "white" }}>Batal</Button>
            <Button
              type="primary"
              htmlType="submit"
              onClick={showConfirmationModal}
            >
              Simpan
            </Button>
          </Space>
        }
      />

      <div style={{ margin: "24px", paddingBottom: "24px" }}>
        {_.isEqual(oldMoistureContent, data) ? null : (
          <Alert
            message="Perhatian"
            description="Ada perubahan kadar air pada draft dan yang baru."
            type="success"
            action={
              <Space>
                <Button
                  size="small"
                  type="primary"
                  onClick={() => setMoistureContent(data)}
                >
                  Gunakan yang baru
                </Button>
                <Button
                  size="small"
                  type="link"
                  onClick={() => setMoistureContent(oldMoistureContent)}
                >
                  Gunakan yang lama
                </Button>
              </Space>
            }
            closable
          />
        )}
        <Section
          title="INFORMASI PENGIRIM"
          subtitle="Berikut adalah informasi transaski"
        >
          <Row gutter={[12, 12]}>
            <Col span={12}>
              <Text style={{ color: "#768499", fontSize: "12px" }}>
                ID Transaksi{" "}
              </Text>
              <br />
              <Text style={{ fontSize: "14px" }}>
                {transaction?.transactionId}{" "}
              </Text>
              <br />
            </Col>
            <Col span={12}>
              <Text style={{ color: "#768499", fontSize: "12px" }}>
                Kode Transaksi{" "}
              </Text>
              <br />
              <Text style={{ fontSize: "14px" }}>
                {transaction?.transactionCode}{" "}
              </Text>
              <br />
            </Col>
            <Col span={12}>
              <Text style={{ color: "#768499", fontSize: "12px" }}>
                Dibuat Pada{" "}
              </Text>
              <br />
              <Text style={{ fontSize: "14px" }}>
                {transactionCreated.format("DD MMM YYYY, HH:mm")}
              </Text>
              <br />
            </Col>
            <Col span={12}>
              <Text style={{ color: "#768499", fontSize: "12px" }}>
                Diperbaharui Pada{" "}
              </Text>
              <br />
              <Text style={{ fontSize: "14px" }}>
                {transactionUpdated.format("DD MMM YYYY, HH:mm")}
              </Text>
              <br />
            </Col>
          </Row>
        </Section>
        <Divider />
        <Section
          title="IDENTITAS PENGIRIM"
          subtitle="Berikut adalah identitas nama , jenis mobil, dan plat nomornya"
        >
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Text style={{ color: "#768499", fontSize: "12px" }}>Nama </Text>
              <br />
              <Text style={{ fontSize: "14px" }}>
                {transaction?.senderName}{" "}
              </Text>
            </Col>
            <Col span={12}>
              <Text style={{ color: "#768499", fontSize: "12px" }}>
                Jenis Mobil{" "}
              </Text>
              <br />
              <Text style={{ fontSize: "14px" }}>
                {transaction?.senderCarType}{" "}
              </Text>
            </Col>
            <Col span={12}>
              <Text style={{ color: "#768499", fontSize: "12px" }}>
                Plat Nomor{" "}
              </Text>
              <br />
              <Text style={{ fontSize: "14px" }}>
                {transaction?.senderPlateNumber}
              </Text>
            </Col>
          </Row>
        </Section>
        <Divider />
        <Section
          title="BERAT"
          subtitle="Berikut adalah perhitungan berat jagung kotor. Berat Jagung Kotor = Berat Kotor - Berat Kosong"
        >
          <Row gutter={[12, 12]}>
            <Col span={24} style={{ padding: "9px 8px" }}>
              <Row>
                <Col style={{ margin: "auto 0" }} span={5}>
                  <Text style={{ color: "#768499" }}>Berat Kotor </Text>
                </Col>
                <Col span={3} style={{ margin: "auto 0" }}>
                  <InputNumber
                    required
                    addonAfter="Kg"
                    controls={false}
                    value={transaction.grossWeight}
                    onChange={(value) => setGrossWeight(value)}
                  />
                </Col>
                <Col style={{ margin: "auto 0", padding: "15px" }} span={12}>
                  <Text style={{ color: "#768499" }}>
                    Berat Barang + Pickup/Truck + (Berat Kotor)
                  </Text>
                </Col>
                <Col style={{ margin: "auto 0", textAlign: "right" }} span={4}>
                  <Text strong style={{ fontSize: "14px" }}>
                    {transaction.grossWeight} Kg
                  </Text>
                </Col>
              </Row>
            </Col>
            <Col span={24} style={{ padding: "9px 8px" }}>
              <Row>
                <Col style={{ margin: "auto 0" }} span={5}>
                  <Text style={{ color: "#768499" }}>Berat Kosong </Text>
                </Col>
                <Col style={{ margin: "auto 0" }} span={3}>
                  <InputNumber
                    required
                    addonAfter="Kg"
                    controls={false}
                    max={transaction.grossWeight}
                    value={transaction.emptyWeight}
                    onChange={(value) => setEmptyWeight(value)}
                  />
                </Col>
                <Col style={{ margin: "auto 0", padding: "15px" }} span={12}>
                  <Text style={{ color: "#768499" }}>
                    Pickup/Truck + (Berat Kotor)
                  </Text>
                </Col>
                <Col style={{ margin: "auto 0", textAlign: "right" }} span={4}>
                  <Text strong style={{ fontSize: "14px", color: "#FE4545" }}>
                    ({transaction.emptyWeight} Kg)
                  </Text>
                </Col>
              </Row>
            </Col>
            <Col style={{ padding: 0 }} span={24}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "9px 8px",
                  backgroundColor: "#E8ECF3",
                  width: "100%",
                }}
              >
                <Text strong>Berat Jagung Kotor </Text>
                <Text strong style={{ fontSize: "14px" }}>
                  {totalGrossWeight} Kg
                </Text>
              </div>
            </Col>
          </Row>
        </Section>
        <Divider />
        <Section
          title="KADAR AIR"
          subtitle="Berikut adalah kadar air.
            Kadar Air diisi dengan jumlah kadar air pada jagung dari total berat jagung kotor"
        >
          {/* check if total totalGrossWeight - moistureContentTotalWeight is not 0 */}
          {totalGrossWeight - moistureContentTotalWeight !== 0 && (
            <Card
              className="card-no-padding"
              style={
                totalGrossWeight - moistureContentTotalWeight > 0
                  ? {
                      padding: "15px",
                      borderRadius: "3px",
                      backgroundColor: "#FFF8E4",
                      border: "none",
                      marginBottom: "12px",
                    }
                  : {
                      padding: "15px",
                      borderRadius: "3px",
                      backgroundColor: "#FFF2F0",
                      border: "none",
                      marginBottom: "12px",
                    }
              }
            >
              <Meta
                avatar={
                  <InfoCircleOutlined
                    style={
                      totalGrossWeight - moistureContentTotalWeight > 0
                        ? { color: "#FFBC00" }
                        : { color: "#FF4D4F" }
                    }
                  />
                }
                description={
                  <Text>
                    Sisa Berat Jagung Kotor:{" "}
                    <strong>
                      {totalGrossWeight - moistureContentTotalWeight}kg
                    </strong>
                    ,
                    {totalGrossWeight - moistureContentTotalWeight > 0
                      ? `total berat untuk kadar air tidak boleh kurang dari${" "}`
                      : `total berat untuk kadar air tidak boleh melebihi${" "}`}
                    <strong>{totalGrossWeight}kg</strong>
                  </Text>
                }
              />
            </Card>
          )}

          <Row gutter={[12, 12]}>
            <Col span={24} style={{ padding: "0px 8px" }}>
              <List
                dataSource={moistureContent}
                renderItem={(item) => {
                  const MoistureContentWeight =
                    transaction.TransactionMoistureContents.find(
                      (x) => x.moistureContentId === item.moistureContentId
                    )?.seedWeight || 0;
                  return (
                    <Row>
                      <Col style={{ margin: "auto 0" }} span={5}>
                        <Text style={{ color: "#768499" }}>
                          {item.minimumLimit} - {item.maximumLimit}%{" "}
                        </Text>
                      </Col>
                      <Col style={{ margin: "auto 0" }} span={3}>
                        <InputNumber
                          addonAfter="Kg"
                          controls={false}
                          width={"100%"}
                          onChange={(value) =>
                            setMoistureContentWeight(
                              value,
                              item.moistureContentId
                            )
                          }
                          defaultValue={MoistureContentWeight}
                        />
                      </Col>
                      <Col
                        style={{ margin: "auto 0", padding: "15px" }}
                        span={1}
                      >
                        <Text style={{ color: "#768499" }}>x</Text>
                      </Col>
                      <Col style={{ margin: "auto 0" }} span={11}>
                        <Text style={{ color: "#768499" }}>
                          Potongan {item.percentage}%
                        </Text>
                      </Col>
                      <Col
                        style={{ margin: "auto 0", textAlign: "right" }}
                        span={4}
                      >
                        <Text strong style={{ fontSize: "14px" }}>
                          {Math.floor((MoistureContentWeight * (100 - item.percentage)) /
                            100)}{" "}
                          Kg
                        </Text>
                      </Col>
                    </Row>
                  );
                }}
              />
            </Col>
            <Col style={{ padding: 0 }} span={24}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "9px 8px",
                  backgroundColor: "#E8ECF3",
                }}
              >
                <Text strong>Berat Jagung Bersih </Text>
                <Text strong style={{ fontSize: "14px" }}>
                  {transaction.netWeight} Kg
                </Text>
              </div>
            </Col>
          </Row>
        </Section>
        <Divider />
        <Section
          title="RINGKASAN TRANSAKSI"
          subtitle="Berikut adalah total pengeluaran dari pengurangan Harga Jagung Bersih dengan Total Potongan."
          style={{ backgroundColor: "#F4F6F9" }}
        >
          <Row gutter={[12, 12]}>
            <Col span={24} style={{ padding: "0px 8px" }}>
              <Row>
                <Col style={{ margin: "auto 0" }} span={5}>
                  <Text>Harga Jagung Bersih</Text>
                </Col>
                <Col style={{ margin: "auto 0", padding: "15px" }} span={3}>
                  <Text style={{ color: "#768499" }}>
                    {transaction.netWeight} kg
                  </Text>
                </Col>
                <Col style={{ margin: "auto 0" }} span={1}>
                  <Text style={{ color: "#768499" }}>x</Text>
                </Col>
                <Col style={{ margin: "auto 0" }} span={4}>
                  <InputNumber
                    required
                    addonBefore="Rp"
                    controls={false}
                    value={transaction.netCornPrice}
                    onChange={(value) => setNetPrice(value)}
                  />
                </Col>
                <Col span={7}></Col>
                <Col
                  style={{
                    margin: "auto 0",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  span={4}
                >
                  <Text strong style={{ fontSize: "14px" }}>
                    Rp
                  </Text>
                  <Text strong style={{ fontSize: "14px" }}>
                    {priceFormat(
                      transaction.netWeight * transaction.netCornPrice
                    ).substring(3)}
                  </Text>
                </Col>
              </Row>
            </Col>
            {/* <Col span={24} style={{ padding: "0px 8px" }}>
              <Row gutter={[0, 10]}>
                <Col style={{ margin: "auto 0" }} span={5}>
                  <Checkbox
                    onChange={(value) => setDeadSeedStatus(value)}
                    checked={isDeadSeedActive}
                  >
                    Biji Mati
                  </Checkbox>
                </Col>
                <Col
                  style={{ margin: "auto 0", padding: "15px" }}
                  span={3}
                ></Col>
                <Col style={{ margin: "auto 0" }} span={1}></Col>
                <Col style={{ margin: "auto 0" }} span={4}>
                  <InputNumber
                    addonBefore="Rp"
                    controls={false}
                    disabled={!isDeadSeedActive}
                    value={transaction.deadSeedPrice}
                    onChange={(value) => setDeadSeedPrice(value)}
                  />
                </Col>
                <Col style={{ margin: "auto 0", padding: "0 10px" }} span={7}>
                  <Text style={{ color: "#3F4C5F" }}>/Kg</Text>
                </Col>
                <Col
                  style={{
                    margin: "auto 0",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  span={4}
                >
                  <Text strong style={{ fontSize: "14px", color: "#FE4545" }}>
                    (Rp
                  </Text>
                  <Text strong style={{ fontSize: "14px", color: "#FE4545" }}>
                    {priceFormat(
                      transaction.deadSeedPrice
                        ? transaction.deadSeedPrice * transaction.netWeight
                        : 0
                    ).substring(3)}
                    )
                  </Text>
                </Col>
                <Col style={{ margin: "auto 0" }} span={24}>
                  {isDeadSeedActive && (
                    <Space>
                      {transaction.deadSeedComment ? (
                        <>
                          <IconArrow />
                          <Text>{transaction.deadSeedComment}</Text>
                          <Link
                            strong
                            style={{ color: "#188007" }}
                            onClick={showDeadSeedModal}
                          >
                            Ubah
                          </Link>
                        </>
                      ) : (
                        <>
                          <IconArrow />
                          <Link
                            strong
                            style={{ color: "#188007" }}
                            onClick={showDeadSeedModal}
                          >
                            Tambah Catatan
                          </Link>
                        </>
                      )}
                    </Space>
                  )}
                </Col>
              </Row>
            </Col>
            <Col span={24} style={{ padding: "0px 8px" }}>
              <Row gutter={[0, 10]}>
                <Col style={{ margin: "auto 0" }} span={5}>
                  <Checkbox
                    onChange={(value) => setLimeSeedStatus(value)}
                    checked={isLimeSeedActive}
                  >
                    Biji Kapur
                  </Checkbox>
                </Col>
                <Col
                  style={{ margin: "auto 0", padding: "15px" }}
                  span={3}
                ></Col>
                <Col style={{ margin: "auto 0" }} span={1}></Col>
                <Col style={{ margin: "auto 0" }} span={4}>
                  <InputNumber
                    addonBefore="Rp"
                    controls={false}
                    disabled={!isLimeSeedActive}
                    value={transaction.limeSeedPrice}
                    onChange={(value) => setLimeSeedPrice(value)}
                  />
                </Col>
                <Col style={{ margin: "auto 0", padding: "0 10px" }} span={7}>
                  <Text style={{ color: "#3F4C5F" }}>/Kg</Text>
                </Col>
                <Col
                  style={{
                    margin: "auto 0",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  span={4}
                >
                  <Text strong style={{ fontSize: "14px", color: "#FE4545" }}>
                    (Rp
                  </Text>
                  <Text strong style={{ fontSize: "14px", color: "#FE4545" }}>
                    {priceFormat(
                      transaction.limeSeedPrice
                        ? transaction.limeSeedPrice * transaction.netWeight
                        : 0
                    ).substring(3)}
                    )
                  </Text>
                </Col>
                <Col style={{ margin: "auto 0" }} span={24}>
                  {isLimeSeedActive && (
                    <Space>
                      {transaction.limeSeedComment ? (
                        <>
                          <IconArrow />
                          <Text>{transaction.limeSeedComment}</Text>
                          <Link
                            strong
                            style={{ color: "#188007" }}
                            onClick={showLimeSeedModal}
                          >
                            Ubah
                          </Link>
                        </>
                      ) : (
                        <>
                          <IconArrow />
                          <Link
                            strong
                            style={{ color: "#188007" }}
                            onClick={showLimeSeedModal}
                          >
                            Tambah Catatan
                          </Link>
                        </>
                      )}
                    </Space>
                  )}
                </Col>
              </Row>
            </Col> */}
            <Col span={24} style={{ padding: "0px 8px" }}>
              <Row gutter={[0, 10]}>
                <Col style={{ margin: "auto 0" }} span={5}>
                  Bri Link
                  {/* <Checkbox onChange={(value) => setLimeSeedStatus(value)}>
                      Biji Kapur
                    </Checkbox> */}
                </Col>
                <Col
                  style={{ margin: "auto 0", padding: "15px" }}
                  span={3}
                ></Col>
                <Col style={{ margin: "auto 0" }} span={1}></Col>
                <Col style={{ margin: "auto 0" }} span={4}>
                  <InputNumber
                    addonBefore="Rp"
                    controls={false}
                    // disabled={!isLimeSeedActive}
                    onChange={(value) => setBriLink(value)}
                    defaultValue={0}
                  />
                </Col>
                <Col style={{ margin: "auto 0", padding: "0 10px" }} span={7}>
                  <Text style={{ color: "#3F4C5F" }}>/Kg</Text>
                </Col>
                <Col
                  style={{
                    margin: "auto 0",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  span={4}
                >
                  <Text strong style={{ fontSize: "14px", color: "#FE4545" }}>
                    (Rp
                  </Text>
                  <Text strong style={{ fontSize: "14px", color: "#FE4545" }}>
                    {priceFormat(transaction.briLink).substring(3)})
                  </Text>
                </Col>
              </Row>
            </Col>
            <Col style={{ padding: 0 }} span={24}>
              <Row style={{ padding: "9px 8px", backgroundColor: "#1E1E1E" }}>
                <Col style={{ margin: "auto 0" }} span={20}>
                  <Text strong style={{ color: "#FFFFFF" }}>
                    Total Transaksi
                  </Text>
                </Col>
                <Col
                  style={{
                    margin: "auto 0",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  span={4}
                >
                  <Text strong style={{ fontSize: "14px", color: "#FFFFFF" }}>
                    Rp
                  </Text>
                  <Text strong style={{ fontSize: "14px", color: "#FFFFFF" }}>
                    {transaction.totalPrice
                      .toLocaleString("id-ID")
                      .replace(/,/g, ".")}
                  </Text>
                </Col>
              </Row>
            </Col>
          </Row>
        </Section>
      </div>

      <Modal
        title="Apakah anda yakin?"
        open={isModalConfirmationShow}
        onOk={sendData}
        onCancel={hideConfirmationModal}
        className="custom-modal"
        okText="Ya, Simpan & Cetak"
        cancelText="Batal"
      >
        <p>
          Apakah anda yakin ingin menyimpan data transaksi ini?. Pastikan semua
          input data sudah sesuai
        </p>
      </Modal>
      {/* <ModalForm
        title="Edit Catatan Biji Mati"
        open={isModalDeadSeedShow}
        onCancel={hideDeadSeedModal}
        onCreate={(value) => setDeadSeedComment(value)}
        defaultValue={transaction.deadSeedComment || ""}
      />
      <ModalForm
        title="Edit Catatan Biji Kapur"
        open={isModalLimeSeedShow}
        onCancel={hideLimeSeedModal}
        onCreate={(value) => setLimeSeedComment(value)}
        defaultValue={transaction.limeSeedComment || ""}
      /> */}
    </React.Fragment>
  );
};

const ModalForm: React.FC<ModalFormProps> = ({
  open,
  onCreate,
  onCancel,
  title,
  defaultValue,
}) => {
  const [form] = Form.useForm();
  return (
    <Modal
      open={open}
      title={title}
      okText="Simpan"
      cancelText="Batal"
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      onOk={() => {
        onCreate(form.getFieldValue("catatan"));
        form.resetFields();
        onCancel();
      }}
    >
      <Form form={form} layout="vertical" name="form_in_modal">
        <Form.Item name="catatan" label="Catatan">
          <Input defaultValue={defaultValue} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditTransaction;
