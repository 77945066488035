import React, { useMemo } from "react";
import { Menu } from "antd";
import {
  iconBook as IconBook,
  // iconChartLine as IconChartLine,
  iconUserCircle as IconUserCircle,
  iconCreditCard as IconCreditCard,
  iconListUnordered as IconListUnordered,
  iconMoreGridBig as IconMoreGridBig,
  iconUsers as IconUsers,
} from "../assets/icons/Sidebar";
import type { MenuProps } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
// import { EUserRole } from "../types/user.type";
import useAuth from "../hooks/useAuth";
import { theme } from "../assets/theme";
import useConfigApp from "../hooks/useConfigApp";
import { ExperimentOutlined, FileAddOutlined, HomeOutlined, LineChartOutlined } from "@ant-design/icons";

const Sidebar: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { role } = useAuth();
  const { sidebarCollapsed } = useConfigApp();

  // const isHavePermission = (finds: EUserRole[]) => {
  //   return finds.includes(role);
  // };

  const filterPermission = (items: any[]) => {
    return items
      .map((item) => {
        if (item?.permission === undefined || item.permission === true) {
          let newObject = item;
          delete item.permission;
          if (newObject.children) {
            newObject.children = filterPermission(newObject.children);
          }
          return newObject;
        } else {
          return null;
        }
      })
      .filter(Boolean);
  };

  const divider = "-------";

  const MENUS = [
    {
      permission: ['admin', "supervisor_admin"].includes(role),
      type: "group",
      children: [
        {
          key: "/dashboard",
          label: "Dashboard",
          icon: <HomeOutlined />,
        }
      ]
    },
    {
      type: "group",
      label: `${sidebarCollapsed ? divider : "TRANSACTION"}`,
      children: [
        {
          key: "/transactions",
          label: "Transaction",
          icon: <FileAddOutlined />,
        },
      ]
    },
    {
      type: "group",
      label: `${sidebarCollapsed ? divider : "MASTER DATA"}`,
      children: [
        {
          key: "/kadarAir",
          label: "Kadar Air",
          icon: <ExperimentOutlined />,
        },
        {
          permission: role == 'admin',
          key: "/admin",
          label: "User",
          icon: <IconUsers />,
        },
      ]
    },
    // {
    //   permission: isAdmin,
    //   type: "group",
    //   // label: `${sidebarCollapsed ? '---------' : 'REPORT & TRANSACTION'}`,
    //   label: `${sidebarCollapsed ? divider : "TRANSACTION"}`,
    //   children: [
    //     {
    //       key: "/report",
    //       label: "Report",
    //       icon: <LineChartOutlined />,
    //     },
    //     {
    //       key: "/transactions",
    //       label: "Transaction",
    //       icon: <IconListUnordered />,
    //     },
    //   ],
    // },
    // {
    //   permission: isAdmin,
    //   type: "group",
    //   label: `${sidebarCollapsed ? divider : "MASTER & CONFIG"}`,
    //   children: [
    //     {
    //       key: "/product",
    //       label: "Product",
    //       icon: <IconMoreGridBig />,
    //     },
    //     {
    //       key: "/payment-config",
    //       label: "Payment & Config",
    //       icon: <IconCreditCard />,
    //     },
    //     {
    //       key: "/faqs",
    //       label: "FAQs",
    //       icon: <IconBook />,
    //     },
    //   ],
    // },
    // {
    //   permission: isAdmin,
    //   type: "group",
    //   label: `${sidebarCollapsed ? divider : "USERS"}`,
    //   children: [
    //     {
    //       key: "/admin",
    //       label: "Admin",
    //       icon: <IconUserCircle />,
    //     },

    //   ],
    // },
  ];

  const items = filterPermission(MENUS) as MenuProps["items"];

  const convertPathName = () => {
    const pathname = location.pathname.split("/");
    const res = "/" + pathname[1];
    // console.log(res);
    return res;
  };

  const getSelectedParent = () => {
    const currentMenu = convertPathName();
    var parent: string[] = [];
    if (items) {
      for (const group of items) {
        if (group && "children" in group && group.children) {
          for (const submenu of group.children) {
            var current = String(submenu?.key ? submenu.key : "");
            if (submenu && "children" in submenu && submenu.children) {
              for (const item of submenu.children) {
                if (
                  item &&
                  "path" in item &&
                  (item as any).path === currentMenu &&
                  current !== undefined
                ) {
                  parent.push(current);
                }
              }
            }
          }
        }
      }
    }
    return parent;
  };

  return (
    <>
      {/* <button onClick={() => console.log(sidebarCollapsed)}>show</button> */}
      <Menu
        style={{
          backgroundColor: theme.colors.primary,
        }}
        mode="inline"
        defaultSelectedKeys={[convertPathName()]}
        defaultOpenKeys={getSelectedParent()}
        items={items}
        onClick={({ key }) => {
          navigate(key);
        }}
      />
    </>
  );
};
export default Sidebar;
