import React from "react";
import { Button, Divider, Layout, Tooltip, Typography } from "antd";
import { DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons";
import styled from "styled-components";
import UserAvatar from "../../components/UserAvatar";
import { appVersion, now, thisYear } from "../../helpers/constant";
import useConfigApp from "../../hooks/useConfigApp";
import AppLogo from "../../components/AppLogo";
import Breadcrumbs from "../../components/Breadcrumbs";
import Sidebar from "../../components/Sidebar";
import { theme } from "../../assets/theme";
import SwitchPaymentId from "../../components/SwitchPaymentId";
import { useLocation } from "react-router-dom";

const { Sider, Content } = Layout;
const { Text } = Typography;

type Props = {
  children: React.ReactNode;
};

const AppLayout: React.FC<Props> = ({ children }) => {
  const { sidebarCollapsed, toggleSidebar } = useConfigApp();
  const location = useLocation();

  const convertPathName = () => {
    const pathname = location.pathname.split("/");
    const res = "/" + pathname[1];
    // console.log(res);
    return res;
  };

  return (
    <Layout
      style={{
        height: "100vh",
        flexDirection: "row",
      }}
    >
      <AppSider
        onCollapse={toggleSidebar}
        trigger={null}
        collapsible
        collapsed={sidebarCollapsed}
        collapsedWidth={70}
        width={250}
      >
        <div
          style={{
            height: "90%",
            overflow: "auto",
          }}
        >
          <AppLogo collapsed={sidebarCollapsed} />
          <Divider
            style={{
              margin: 0,
              backgroundColor: `${theme.colors.charcoal600}`,
            }}
          />
          <div style={{ padding: 10, paddingTop: 0, paddingBottom: 0 }}>
            <Sidebar />
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            bottom: 0,
          }}
        >
          {sidebarCollapsed ? (
            <Tooltip placement="right" title={"Expand Sidebar"}>
              <Button
                style={{
                  background: "#282A35",
                  height: 40,
                  width: 70,
                }}
                onClick={toggleSidebar}
                type="text"
                icon={
                  <DoubleRightOutlined
                    color="#FFFFFF"
                    className="icon-collapsed"
                    style={{ fontSize: 20 }}
                  />
                }
              />
            </Tooltip>
          ) : (
            <MinimizeButton
              onClick={toggleSidebar}
              style={{
                background: "#282A35",
                height: 40,
                width: 250,
              }}
            >
              <DoubleLeftOutlined color="#FFFFFF" style={{ fontSize: 20 }} />
              <Text
                style={{
                  color: theme.colors.charcoal400,
                  width: "100%",
                  textAlign: "center",
                }}
              >
                Version {appVersion}
              </Text>
            </MinimizeButton>
          )}
        </div>
      </AppSider>

      <Layout
        id="scroll-content"
        className="site-layout"
        style={{
          height: "100vh",
          overflowY: "auto",
        }}
      >
        <AppHeader>
          <Breadcrumbs />
          <AppHeaderAccount>
            {/* {(convertPathName() === "/transactions" || convertPathName() === '/report') ? (
              <SwitchPaymentId />
            ) : (
              <DateNow>{now}</DateNow>
            )} */}
            <DateNow>{now}</DateNow>
            <DividerTitle />
            <UserAvatar />
          </AppHeaderAccount>
        </AppHeader>
        <AppContent>{children}</AppContent>
      </Layout>
    </Layout>
  );
};

const AppContent = styled(Content)`
  height: 100%;
  padding: 80px 0px 20px 0px;
  background-color: ${theme.colors.white};
`;

const AppSider = styled(Sider)`
  background-color: ${({ theme }) => theme.colors.primary};
  overflow: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // height: 100vh,
  // border: 1px solid red;
  padding: 0;
`;

const AppHeader = styled.div`
  background-color: white;
  position: fixed;
  padding: 0 20px;
  height: 64px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 99;
  // border: 1px solid green;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
`;

const DateNow = styled.div`
  color: ${({ theme }) => theme.colors.black};
  text-align: right;
`;

const AppHeaderAccount = styled.div`
  flex: 1;
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr auto auto auto;
  align-items: center;
  justify-content: flex-end;
  // border: 1px solid red;
  padding: 0;
  position: fixed;
  right: 10px;
  // margin: 0;
`;

const DividerTitle = styled.div`
  width: 1px;
  height: 150%;
  background-color: ${({ theme }) => theme.colors.ash800};
  opacity: 0.4;
  margin: 0 10px;
`;

const MinimizeButton = styled.div`
  // box-sizing: border-box;
  width: 100%;
  display: flex;
  // flex-direction: column;
  height: 60px;
  align-items: center;
  justify-content: space-between;
  padding: 0px 0px 0px 16px;
  cursor: pointer;
  div {
    color: ${({ theme }) => theme.colors.white};
    font-size: 10px;
  }
  svg {
    fill: ${({ theme }) => theme.colors.white};
  }
`;

export default AppLayout;
