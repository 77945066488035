import React, { Suspense } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import useAuth from '../hooks/useAuth';
import { Spin } from 'antd';
import Transaction from '../screens/transaction';
import CreateTransaction from '../screens/transaction/create';
import DetailTransaction from '../screens/transaction/detail';
import KadarAir from '../screens/kadarAir';
import Dashboard from '../screens/dashboard';
import EditProfile from '../screens/profile/Edit';
import Profile from '../screens/profile';
import ChangeMyPassword from '../screens/profile/ChangeMyPassword';
import EditTransaction from '../screens/transaction/edit';

const NotFound = React.lazy(() => import('../NotFound'));

const Login = React.lazy(() => import('../screens/auth/Login'));
const ForgotPassword = React.lazy(() => import('../screens/auth/ForgotPassword'));
const ResetPassword = React.lazy(() => import('../screens/auth/ResetPassword'));

const ChangePasswordUser = React.lazy(() => import('../screens/user/ChangePassword'));
const User = React.lazy(() => import('../screens/user'));
const UserEdit = React.lazy(() => import('../screens/user/Edit'));
const UserDetail = React.lazy(() => import('../screens/user/Detail'));

const RootNavigator: React.FC = () => {
	const { isLoggedIn } = useAuth();

	return (
		<Suspense fallback={<Spin spinning={true} />}>
			<BrowserRouter>
				<Routes>
					<Route
						path='/'
						element={<Navigate to={isLoggedIn ? '/dashboard' : '/login'} />}
					/>
					<Route element={<PublicRoute />}>
						<Route
							path='/login'
							element={<Login />}
						/>
						<Route
							path='/forgot-password'
							element={<ForgotPassword />}
						/>
						<Route
							path='/reset-password/:sessionId'
							element={<ResetPassword />}
						/>
					</Route>
					<Route element={<PrivateRoute />}>
						<Route
							path='*'
							element={<NotFound />}
						/>
						{/* <Route
							path='/report'
							element={<Reports />}
						/>
						<Route
							path='/report/detail'
							element={<ReportDetails />}
						/>
						*/}
						<Route
							path='/profile/edit'
							element={<EditProfile />}
						/>
						<Route
							path='/profile'
							element={<Profile />}
						/>
						<Route
							path='/change-password'
							element={<ChangeMyPassword />}
						/>
						{/*
						<Route
							path='/transactions'
							element={<Transaction />}
						/>
						<Route
							path='/transactions/:transactionId'
							element={<TransactionDetail />}
						/> */}
						<Route
							path='/transactions'
							element={<Transaction />}
						/>

						<Route
							path='/transactions/create'
							element={<CreateTransaction />}
						/>
						<Route
							path='/transactions/:transactionId'
							element={<DetailTransaction />}
						/>

						<Route
							path='/transactions/:transactionId/edit'
							element={<EditTransaction />}
						/>

						<Route
							path='/kadarAir'
							element={<KadarAir />}
						/>

						<Route
							path='/dashboard'
							element={<Dashboard />}
						/>

						{/* <Route
							path='/product'
							element={<Products />}
						/>
						<Route
							path='/product/add'
							element={<ProductEdit />}
						/>
						<Route
							path='/product/:productId/edit'
							element={<ProductEdit />}
						/>
						<Route
							path='/product/:productId'
							element={<ProductDetail />}
						/>

						<Route
							path='/payment-config'
							element={<PaymentConfigs />}
						/>
						<Route
							path='/payment-config/add'
							element={<PaymentConfigsEdit />}
						/>
						<Route
							path='/payment-config/:paymentConfigId/Edit'
							element={<PaymentConfigsEdit />}
						/>
						<Route
							path='/payment-config/:paymentConfigId'
							element={<PaymentConfigsEdit />}
						/>
						<Route
							path='/payment-config/:paymentConfigId/transactions-list'
							element={<TransactionsPaymentConfig />}
						/> */}

						{/* <Route
							path='/faqs'
							element={<FAQ />}
						/>
						<Route
							path='/faqs/add'
							element={<FAQEdit />}
						/>
						<Route
							path='/faqs/:faqId/edit'
							element={<FAQEdit />}
						/> */}

						<Route
							path='/admin'
							element={<User userType='admin' />}
						/>
						<Route
							path='/admin/add'
							element={<UserEdit userType='admin' />}
						/>
						<Route
							path='/admin/:userId/edit'
							element={<UserEdit userType='admin' />}
						/>
						<Route
							path='/admin/:userId'
							element={<UserDetail userType='admin' />}
						/>
						<Route
							path='/admin/:userId/change-password'
							element={<ChangePasswordUser />}
						/>

						{/* <Route
							path='/customer'
							element={<User userType='customer' />}
						/>
						<Route
							path='/customer/add'
							element={<UserEdit userType='customer' />}
						/>
						<Route
							path='/customer/:userId/edit'
							element={<UserEdit userType='customer' />}
						/>
						<Route
							path='/customer/:userId'
							element={<UserDetail userType='customer' />}
						/>
						<Route
							path='/customer/:userId/change-password'
							element={<ChangePasswordUser />}
						/>
						<Route
							path='/customer/:buyerEmail/transactions-list'
							element={<TransactionsUser />}
						/> */}
					</Route>
				</Routes>
			</BrowserRouter>
		</Suspense>
	);
};
export default RootNavigator;
