import { Col, Row, Typography } from "antd";


interface SectionProps {
  title: string;
  subtitle: string;
  children: React.ReactNode | string;
  style?: React.CSSProperties;
}

const { Text } = Typography;

const Section: React.FC<SectionProps> = ({
  title,
  subtitle,
  children,
  style
}) => {
  return (
    <Row style={style}>
      <Col span={6} style={{display:"flex", flexDirection:"column", padding: "15px"}}>
        <Text strong style={{fontSize:"14px", marginBottom: "10px"}}>{title}</Text>
        <Text type="secondary" style={{fontSize:"12px"}}>{subtitle}</Text>
      </Col>
      <Col span={18} style={{padding: "15px"}}>
        {children}
      </Col>
    </Row>
  )
}

export default Section;