import {
  DownOutlined,
  LockOutlined,
  LogoutOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Avatar, Divider, Dropdown, Menu, Typography } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import useAuth from "../hooks/useAuth";
import { generateInitialFromName } from "../helpers/generator";
import { theme } from "../../src/assets/theme";

const { Text } = Typography;

const UserAvatar: React.FC = () => {
  const navigate = useNavigate();
  const { user, logout } = useAuth();
  const initial = generateInitialFromName(user?.name!);

  const handleMenuClick = (e: any) => {
    if (e.key === "profile") {
      navigate("/profile");
    } else if (e.key === "change-password") {
      navigate("/change-password");
    } else {
      logout();
      navigate("/login");
    }
  };

  const ContentDropdown = (
    <Menu style={{ marginTop: 15 }} onClick={handleMenuClick}>
      <Menu.ItemGroup
        title={
          "Welcome, " +
          (user?.name!.length > 15
            ? user?.name!.substring(0, 15) + "..."
            : user?.name)
        }
      >
        <Divider style={{ marginTop: 0, marginBottom: 0 }} />
        <Menu.Item key="profile" icon={<UserOutlined />}>
          My Profil
        </Menu.Item>
        <Menu.Item key="change-password" icon={<LockOutlined />}>
          Change Password
        </Menu.Item>
        <Menu.Item key="logout" icon={<LogoutOutlined />}>
          Logout
        </Menu.Item>
      </Menu.ItemGroup>
    </Menu>
  );

  return (
    <Dropdown overlay={ContentDropdown}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <AppAvatar size="large">{initial}</AppAvatar>
        <span
          style={{ marginRight: 10, display: "flex", flexDirection: "column" }}
        >
          <Text style={{ color: theme.colors.charcoal200 }}>
            {user.userType.toUpperCase()}
          </Text>
          <Text strong>{user.name}</Text>
        </span>
        <Icon />
      </div>
    </Dropdown>
  );
};

const AppAvatar = styled(Avatar)`
  background-color: ${({ theme }) => theme.colors.ash500};
  color: ${({ theme }) => theme.colors.black};
  margin-right: 10px;
`;

const Icon = styled(DownOutlined)`
  color: ${({ theme }) => theme.colors.charcoal800};
`;

export default UserAvatar;
