import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { theme } from '../assets/theme';
import IconSeparator from '../assets/icons/icon-arrow-right.svg';

const Breadcrumbs: React.FC = () => {
  const breadcrumbs = useBreadcrumbs();
  const navigate = useNavigate();

  const renderLabel = (label: any) => {
    const rawArr = label.key.split('/');
    // console.log(rawArr);

    const labels = () => {
      if (rawArr.length === 3) {
        if (rawArr[2] === 'edit') {
          return 'Edit'.split('-');
        }
        return 'Detail'.split('-');
      } else {
        const labels = label.props.children.split('-');
        return labels;
      }
    };
    // const labels = label.props.children.split("-");

    // console.log(label);
    if (labels().length > 0) {
      const newLabel = labels().join(' ').toString();
      return newLabel.length > 20 ? newLabel.substr(0, 20) + '...' : newLabel;
    } else {
      return label.props.children;
    }
  };

  const handleBreadcrumb = (e: any, link: string, locationState: any) => {
    e.preventDefault();

    navigate(link, {
      state: {
        ...locationState,
      },
    });
  };

  return (
    <Breadcrumb separator={'>'}>
      {/* <Button onClick={() => console.log(breadcrumbs)}>Breadcrumbs</Button> */}
      {breadcrumbs.map(({ breadcrumb, match, location }, index) => (
        <Breadcrumb.Item key={match.pathname}>
          {match.pathname === '/' ? (
            <HomeOutlined
              onClick={(e) =>
                handleBreadcrumb(e, match.pathname, location.state)
              }
              style={{ color: theme.colors.primary_green }}
            />
          ) : (
            <div
              key={index}
              onClick={(e) =>
                handleBreadcrumb(e, match.pathname, location.state)
              }
            >
              {renderLabel(breadcrumb)}
            </div>
          )}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

export default Breadcrumbs;
