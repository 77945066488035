const CONFIG = {
	isEditFullName: false,

	showEditEmail: true,
	changeEmailWithOTP: false,

	showEditPhone: true,
	changePhoneWithOTP: false,
	
	phoneRequired: false,
	emailRequired: true,
}

export default CONFIG;